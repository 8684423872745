<template>
  <div class="flex flex-col gap-4">
    <!-- <div class="text-2xl font-bold">
      {{ $t('subscriptions.manage_subscription') }}
    </div> -->
    <div
      class="flex flex-col md:flex-row justify-between gap-3 bg-gray-80 rounded-xl p-4"
    >
      <div class="flex-shrink-0">
        <BaseBadge size="sm" theme="none" class="bg-pro text-white py-3">
          {{ $t('subscriptions.pro') }}
        </BaseBadge>
      </div>
      <div class="flex flex-col gap-2 flex-1">
        <div class="text-gray-400 text-sm mt-0.5">
          {{ getFormattedDate(subscription.start_at) }}
        </div>
        <div class="font-bold text-lg">
          {{
            `${$t('subscriptions.pro_account_subscription')} ${getFormattedDate(
              subscription.start_at
            )} - ${getFormattedDate(subscription.ends_at)}`
          }}
        </div>
        <div v-if="subscription.next_charge_at" class="text-gray-400 text-xs">
          {{
            `${$t('subscriptions.month_description')} ${$t(
              'subscriptions.next_payment'
            )} ${getHumanizedDate(subscription.next_charge_at)}`
          }}
        </div>
        <div
          v-else-if="subscription.cancelled_at"
          class="text-gray-400 text-xs"
        >
          {{ $t('subscriptions.subscription_canceled') }}
        </div>
      </div>
      <div v-if="subscription.next_charge_at" class="flex items-center">
        <BaseButton @click="isCancelModalOpen = true">
          {{ $t('subscriptions.unsubscribe') }}
        </BaseButton>
      </div>
    </div>
    <SubscriptionsCancelModal
      :is-open="isCancelModalOpen"
      @close="isCancelModalOpen = false"
      @unsubscribe="emit('unsubscribe')"
    />
  </div>
</template>

<script setup lang="ts">
import type { Subscription } from '~/models/subscriptions'

interface PropsInterface {
  subscription: Subscription
}

defineProps<PropsInterface>()

const emit = defineEmits(['unsubscribe'])

const { getHumanizedDate, getFormattedDate } = useDate()

const isCancelModalOpen = ref(false)
</script>
