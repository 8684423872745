import {
  DEFAULT_MARKDOWN_TEXT,
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_TEXT,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  titlePropertiesSchema,
  shadowSchema,
  bgColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  linkColorSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Tabs',
  description: 'Tabs with text',
  type: 'object',
  required: ['tabs', 'textColor'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'textColor',
      'bgColor',
      'linkColor',
      'blockShadow',
      'activeTabButtonColor',
      'activeTabTextColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...linkColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    activeTabButtonColor: {
      title: 'Active tab button color',
      type: 'string',
      default: DEFAULT_COLOR_PRIMARY,
    },
    activeTabTextColor: {
      title: 'Active tab text color',
      type: 'string',
      default: '#ffffff',
    },
    tabs: {
      type: 'array',
      title: 'Tabs',
      minItems: 2,
      items: {
        type: 'object',
        required: ['label', 'text'],
        minItems: 1,
        'ui:options': {
          showIndexNumber: true,
        },
        properties: {
          label: {
            title: 'Tab label',
            type: 'string',
            default: DEFAULT_TEXT,
          },
          text: {
            title: 'Text',
            type: 'string',
            default: DEFAULT_MARKDOWN_TEXT,
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_TEXT,
              type: 'textarea',
              rows: 6,
            },
          },
          image: {
            title: 'Image',
            type: 'string',
            default: '',
          },
          imageWidth: {
            title: 'Image width',
            type: 'string',
            enum: ['w-1/2', 'w-1/3', 'w-full'],
            enumNames: ['1/2', '1/3', 'Full'],
            default: 'w-1/3',
            'ui:widget': 'RadioWidget',
            'ui:hidden': '{{!parentFormData.image}}',
          },
          isImageRounded: {
            title: 'Is image rounded',
            type: 'boolean',
            default: false,
            'ui:hidden': '{{!parentFormData.image}}',
          },
        },
      },
    },
  },
}
