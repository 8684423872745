<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      color: formData.textColor,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
      '--text-color': formData.textColor,
      'text-align': formData.textAlign,
      ...useEditorFontFamily(formData.textFontFamily),
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-2 md:mb-6"
      :style="{
        textAlign: formData.titleAlign,
        fontWeight: formData.titleWeight || undefined,
        fontStyle: formData.titleStyle,
        fontSize: formData.titleSizePx || undefined,
        color: formData.titleColor,
        ...useEditorFontFamily(formData.titleFontFamily),
      }"
    >
      {{ formData.title }}
    </h2>
    <div class="flex flex-col md:flex-row gap-6">
      <div class="w-full md:w-1/2 flex flex-col gap-4">
        <div
          class="marked"
          v-html="
            getMarkedTextWithBlankLinks(formData.text, formData.linkColor)
          "
        />
        <div v-if="formData.buttons?.length" class="flex flex-wrap gap-4">
          <template v-for="(button, index) in formData.buttons" :key="index">
            <EditorButton :button="button" />
          </template>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <BaseGallery
          :cols-number-on-desktop="formData.colsNumberOnDesktop"
          :cols-number-on-mobile="formData.colsNumberOnMobile"
          :is-use-slider-on-mobile="formData.isUseSliderOnMobile"
          :is-open-fullscreen="formData.isOpenFullscreen"
          :images="formData.images"
          lazy
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseGallery from '~/components/base/BaseGallery.vue'
import { usePageEditor } from '~/stores/page-editor'
import EditorButton from '~/components/page-editor/EditorButton.vue'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
