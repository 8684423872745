import {
  DEFAULT_MARKDOWN_PLACEHOLDER,
  DEFAULT_COLOR_PRIMARY,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import { buttonsPropertiesSchema, textFontFamilySchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Cover',
  description: 'Beautiful block with background, text and buttons',
  type: 'object',
  required: [],
  properties: {
    ...basePropertiesSchema,
    logo: {
      title: 'Logo or other svg/png image before text',
      type: 'string',
      default: '',
    },
    logoWidth: {
      title: 'Logo width (in px)',
      description: "If it's not set, the logo width will be 240px",
      type: 'number',
    },
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
    },
    textColor: {
      title: 'Text Color',
      type: 'string',
      default: '#ffffff',
    },
    ...textFontFamilySchema,
    linkColor: {
      title: 'Link Color',
      type: 'string',
      default: DEFAULT_COLOR_PRIMARY,
    },
    textAlign: {
      title: 'Text Align',
      enum: ['left', 'center', 'right'],
      enumNames: ['Left', 'Center', 'Right'],
      default: 'center',
      'ui:widget': 'RadioWidget',
    },
    useImageRatio: {
      title: 'Use image ratio',
      type: 'boolean',
      default: false,
      'ui:style':
        '{{ parentFormData.bgImage ? {} : { "pointer-events": "none", opacity: "20%" } }}',
    },
    bgImage: {
      title: 'Background image',
      type: 'string',
      default: '',
    },
    bgColor: {
      title: 'Background color',
      type: 'string',
      'ui:hidden': '{{ !!parentFormData.bgImage }}',
    },
    paddingSize: {
      title: 'Paddings',
      enum: ['xs', 'sm', 'md', 'lg'],
      enumNames: ['Extra small', 'Small', 'Medium', 'Large'],
      default: 'md',
      'ui:widget': 'RadioWidget',
      'ui:hidden':
        '{{ parentFormData.useImageRatio === true && parentFormData.bgImage }}',
    },
    maxWidth: {
      title: 'Max width of the content (in px)',
      description: "If it's not set, the content width will be 576px",
      type: 'number',
    },
    ...buttonsPropertiesSchema,
  },
}
