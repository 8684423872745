import { defineStore } from 'pinia'
import type { UserProfile } from '~/models/users'
import {
  OrderStatus,
  OrderItemActivationStatus,
  Currency,
} from '~/models/tickets'
import { useAuth } from '~/stores/auth'

import type {
  TicketsOrder,
  TicketsOrderResponse,
  TicketDetails,
  UserTicket,
  UpgradeRequestData,
  Payment,
  TicketsForUpgradeResponse,
} from '~/models/tickets'

interface TicketsState {
  ticketsDetails: TicketDetails[]
  order: TicketsOrderResponse | null
  eventId: number | null
  ticketsEventId: number | null
  ordersByEventId: TicketsOrderResponse[]
  userTickets: UserTicket[]
  ticketsForUpgrade: TicketDetails[]
  upgradeCurrency: Currency
  itemsToActivateCount: {
    order: number
    subscription: number
  }
  payments: Payment[]
}

export const useTickets = defineStore('tickets', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi
  const authStore = useAuth()

  const state = reactive<TicketsState>({
    ticketsDetails: [],
    order: null,
    eventId: null,
    ticketsEventId: null,
    ordersByEventId: [],
    userTickets: [],
    ticketsForUpgrade: [],
    upgradeCurrency: Currency.USD,
    itemsToActivateCount: {
      order: 0,
      subscription: 0,
    },
    payments: [],
  })

  function resetState() {
    state.order = null
    state.eventId = null
    state.ticketsEventId = null
    state.ordersByEventId = []
    state.userTickets = []
    state.ticketsForUpgrade = []
    state.upgradeCurrency = Currency.USD
    state.itemsToActivateCount.order = 0
    state.itemsToActivateCount.subscription = 0
    state.payments = []
  }

  async function createOrder(order: TicketsOrder) {
    const { data } = await useAuthFetch<{ data: TicketsOrderResponse }>(
      'orders',
      {
        method: 'POST',
        baseURL,
        body: order,
      }
    )
    return data
  }

  async function checkPromocode(
    code: string,
    eventId: number,
    tickets: { id: number; count: number }[]
  ) {
    const params: { [key: string]: string | number } = {
      code,
      eventId,
    }
    tickets.forEach((entry) => {
      params[`tickets[${entry.id}]`] = String(entry.count)
    })

    const { data } = await useKrakenFetch<{
      data: { discount: { [key: string]: number } }
    }>('promo/discount', {
      baseURL,
      params,
    })

    return data.discount
  }

  async function fetchOrder(
    orderId: number,
    ignoreSavedData = false
  ): Promise<void> {
    if (state.order?.id === orderId && !ignoreSavedData) {
      return
    }

    const { data } = await useAuthFetch<{ data: TicketsOrderResponse }>(
      `orders/${orderId}`,
      {
        baseURL,
      }
    )

    state.order = data
  }

  async function fetchItemsToActivateCount() {
    interface DataInterface {
      order: {
        data: {
          count: number
        }
      }
      subscription: {
        data: {
          count: number
        }
      }
    }

    try {
      const { order, subscription } = await useAuthFetch<DataInterface>(
        'order-items/not-active-count',
        {
          baseURL,
        }
      )

      state.itemsToActivateCount.order = order.data.count
      state.itemsToActivateCount.subscription = subscription.data.count
    } catch (error: any) {
      if (error.statusCode !== 404) {
        throw error
      }
    }
  }

  const initOrdersList = () => {
    const path = '/orders'
    const list = useInfinityList<TicketsOrderResponse>(path, baseURL)

    list.state.params = { per_page: 6 }
    return list
  }

  const ordersList = initOrdersList() // init on start to avoid SSR state dehydration issues

  function formatPrice(
    value: number,
    currency = 'USD',
    language = 'en-US',
    fractionDigits = 2
  ) {
    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits,
    })

    return formatter.format(value)
  }

  const countOrderItemsToActivate = (order: TicketsOrderResponse) => {
    if ([OrderStatus.Created, OrderStatus.Canceled].includes(order.status)) {
      return 0
    }

    return order.items.reduce((acc, item) => {
      if (
        item.activation_status === OrderItemActivationStatus.Created &&
        !item.email
      ) {
        acc += 1
      }
      return acc
    }, 0)
  }

  async function getOrderPaymentLink(orderId: number) {
    const { data } = await useAuthFetch<{
      data: Partial<TicketsOrderResponse>
    }>(`orders/${orderId}/payment-link`, {
      baseURL,
      method: 'POST',
    })

    return data
  }

  async function activateOrderItem(
    itemId: number,
    memberData: Partial<UserProfile>
  ): Promise<void> {
    await useAuthFetch<{ data: string }>(`order-items/${itemId}/activate`, {
      method: 'POST',
      baseURL,
      body: memberData,
    })
  }

  async function fetchTicketsForUpgrade(ticketId: number): Promise<void> {
    const { data } = await useAuthFetch<{
      data: TicketsForUpgradeResponse
    }>(`tickets/${ticketId}/for-upgrade`, {
      baseURL,
    })

    state.ticketsForUpgrade = data.tickets_for_upgrade
    state.upgradeCurrency = data.current_ticket_currency
  }

  async function fetchUserTicketList(update = false): Promise<void> {
    if (state.userTickets.length && !update) {
      return
    }

    const { data } = await useAuthFetch<{ data: UserTicket[] }>('tickets/me', {
      baseURL,
    })

    state.userTickets = data
  }

  async function getUpgradePaymentLink(upgradeData: UpgradeRequestData) {
    const { data } = await useAuthFetch<{
      data: Partial<TicketsOrderResponse>
    }>('/upgrade-tickets', {
      baseURL,
      method: 'POST',
      body: upgradeData,
    })

    return data
  }

  async function fetchOrdersByEventId(eventId: number): Promise<void> {
    if (
      !eventId ||
      (state.eventId === eventId && state.ordersByEventId.length)
    ) {
      return
    }

    try {
      const { data } = await useAuthFetch<{ data: TicketsOrderResponse[] }>(
        'orders',
        {
          baseURL,
          params: {
            'filter[event_id]': eventId,
          },
        }
      )

      state.eventId = eventId
      state.ordersByEventId = data
    } catch (error: any) {
      if (error.statusCode === 404) {
        state.eventId = eventId
        state.ordersByEventId = []
      } else {
        throw error
      }
    }
  }

  async function fetchTicketListDetails(eventId?: number) {
    if (
      !eventId ||
      (state.ticketsDetails.length && state.eventId === eventId)
    ) {
      return
    }

    const { data } = await useKrakenFetch<{ data: TicketDetails[] }>(
      `${eventId}/tickets`,
      {
        baseURL,
      }
    )

    state.eventId = eventId
    state.ticketsDetails = data
  }

  async function fetchPaymentsList() {
    if (state.payments.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: Payment[] }>(
      'payments/history',
      {
        baseURL,
      }
    )

    state.payments = data
  }

  function resetPaymentsList() {
    state.payments = []
  }

  async function resendStripeReceipt(orderId: number): Promise<void> {
    await useAuthFetch<{ data: Payment[] }>(`orders/${orderId}/send-check`, {
      baseURL,
    })
  }

  watch(
    () => authStore.auth,
    (isAuth) => {
      if (!isAuth) {
        resetState()
      }
    }
  )

  return {
    order: toRef(state, 'order'),
    userTickets: toRef(state, 'userTickets'),
    ticketsEventId: toRef(state, 'ticketsEventId'),
    ordersByEventId: toRef(state, 'ordersByEventId'),
    ticketsDetails: toRef(state, 'ticketsDetails'),
    ticketsDetailsMap: computed(() =>
      state.ticketsDetails.reduce((map, ticket) => {
        map[ticket.id] = ticket
        return map
      }, {} as { [key: number]: TicketDetails })
    ),
    ticketsForUpgrade: toRef(state, 'ticketsForUpgrade'),
    upgradeCurrency: toRef(state, 'upgradeCurrency'),
    itemsToActivateCount: toRef(state, 'itemsToActivateCount'),
    payments: toRef(state, 'payments'),
    orders: ordersList.state,
    getOrders: ordersList.getPage,
    getNext: ordersList.nextPage,
    allOrdersLoaded: ordersList.allRecordsLoaded,
    reset: ordersList.reset,
    createOrder,
    checkPromocode,
    fetchOrder,
    formatPrice,
    countOrderItemsToActivate,
    getOrderPaymentLink,
    activateOrderItem,
    fetchItemsToActivateCount,
    fetchTicketsForUpgrade,
    fetchUserTicketList,
    getUpgradePaymentLink,
    fetchOrdersByEventId,
    fetchTicketListDetails,
    fetchPaymentsList,
    resetPaymentsList,
    resetUserTickets: () => (state.userTickets = []),
    resetUserOrdersByEventId: () => (state.ordersByEventId = []),
    // we should return state data even if we don't use it.
    // Otherwise pinia doesn't save it between server and client side
    currentEventId: toRef(state, 'eventId'),
    resendStripeReceipt,
  }
})
