import {
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_MARKDOWN_PLACEHOLDER,
  DEFAULT_COLOR_WHITE,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  showAllSettingsSchema,
  titlePropertiesSchema,
  textColorSchema,
  textFontFamilySchema,
  buttonsPropertiesSchema,
  linkColorSchema,
  getButtonProperties,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Tickets',
  description: 'Tickets',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['bgColor', 'textColor'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    subtitle: {
      title: 'Subtitle',
      type: 'string',
    },
    ...showAllSettingsSchema,
    ...bgColorSchema,
    subtitleAlign: {
      ...titlePropertiesSchema.titleAlign,
      title: 'Subtitle align',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleWeight: {
      ...titlePropertiesSchema.titleWeight,
      title: 'Subtitle weight',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleStyle: {
      ...titlePropertiesSchema.titleStyle,
      title: 'Subtitle style',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleSizePx: {
      ...titlePropertiesSchema.titleSizePx,
      title: 'Subtitle size',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleColor: {
      ...titlePropertiesSchema.titleColor,
      title: 'Subtitle color',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      title: 'Subtitle font family',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    categories: {
      type: 'array',
      title: 'A list of category cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Category card',
        type: 'object',
        required: ['title'],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            default: 'Category title',
          },
          color: {
            title: 'Card color',
            type: 'string',
            default: DEFAULT_COLOR_PRIMARY,
          },
          titleColor: {
            ...textColorSchema.textColor,
            title: 'Card title color',
            default: DEFAULT_COLOR_WHITE,
          },
          titleFontFamily: {
            ...textFontFamilySchema.textFontFamily,
            title: 'Card title font family',
          },
          textColor: {
            ...textColorSchema.textColor,
            title: 'Card text color',
          },
          textFontFamily: {
            ...textFontFamilySchema.textFontFamily,
            title: 'Card text font family',
          },
          isShowVat: {
            title: 'Is show VAT',
            type: 'boolean',
            default: true,
          },
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          aboutTicketText: {
            title: 'About ticket text',
            type: 'string',
            default: 'about ticket',
          },
          popupTitle: {
            title: 'Popup title',
            type: 'string',
            default: 'About ticket',
          },
          additionalInfo: {
            title: 'Additional info for popup',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          isSubscriptionSection: {
            title: 'Show subscription button',
            description: 'Category should have no tickets for this feature',
            type: 'boolean',
            default: false,
          },
          subscribeButton: {
            type: 'array',
            items: {
              type: 'object',
              properties: getButtonProperties({
                text: 'Subscribe',
                url: '/pro',
              }),
            },
            title: 'Subscribe button (no subscription)',
            'ui:hidden': '{{parentFormData.isSubscriptionSection !== true }}',
            'ui:options': {
              showIndexNumber: false,
              addable: false,
              sortable: false,
              removable: false,
            },
            minItems: 1,
            maxItems: 1,
          },
          subscriptionActiveButton: {
            type: 'array',
            items: {
              type: 'object',
              properties: getButtonProperties({
                text: 'Subscription Active!',
                url: '/pro',
              }),
            },
            title: 'Subscribe button (subscription active)',
            'ui:hidden': '{{parentFormData.isSubscriptionSection !== true }}',
            'ui:options': {
              showIndexNumber: false,
              addable: false,
              sortable: false,
              removable: false,
            },
            minItems: 1,
            maxItems: 1,
          },
          tickets: {
            type: 'array',
            title: 'Linked tickets',
            'ui:options': {
              showIndexNumber: true,
            },
            items: {
              title: 'Ticket',
              type: 'object',
              required: ['label'],
              properties: {
                label: {
                  title: 'Name',
                  type: 'string',
                },
                value: {
                  title: 'Ticket id',
                  type: 'number',
                },
                maxTicketsCount: {
                  title: 'Max tickets count',
                  type: 'number',
                  default: 99,
                  minimum: 1,
                  maximum: 99,
                },
                freeTicketText: {
                  title: 'Free ticket price text',
                  type: 'string',
                  default: 'Free',
                },
              },
            },
          },
        },
      },
    },
    footerText: {
      title: 'Footer text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
      'ui:widget': 'EditorMarkdownWidget',
    },
    footerTextColor: {
      ...textColorSchema.textColor,
      title: 'Footer text color',
      default: undefined,
    },
    footerTextLinkColor: {
      ...linkColorSchema.linkColor,
      title: 'Footer text link color',
    },
    footerTextFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      title: 'Footer text font family',
    },
    footerButtons: {
      ...buttonsPropertiesSchema.buttons,
      title: 'Footer buttons',
    },
    showBanner: {
      title: 'Show promo banner',
      type: 'boolean',
      default: false,
    },
    bannerBackgroundColor: {
      title: 'Promo banner background color',
      type: 'string',
      default: DEFAULT_COLOR_PRIMARY,
      'ui:widget': 'BaseColorWidget',
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerTextSize: {
      title: 'Promo banner font size, px',
      type: 'number',
      default: 16,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerTextWeight: {
      title: 'Promo banner font weight (100-900)',
      type: 'number',
      default: 700,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerTextColor: {
      title: 'Promo banner text color',
      type: 'string',
      default: DEFAULT_COLOR_WHITE,
      'ui:widget': 'BaseColorWidget',
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerBorderColor: {
      title: 'Promo banner border color',
      type: 'string',
      default: DEFAULT_COLOR_WHITE,
      'ui:widget': 'BaseColorWidget',
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerBorderWidth: {
      title: 'Promo banner border width, px',
      type: 'number',
      default: 2,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerBorderRadius: {
      title: 'Promo banner border radius, px',
      type: 'number',
      default: 20,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerWidth: {
      title: 'Promo banner width, px',
      type: 'number',
      default: 320,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerText: {
      title: 'Promo banner text',
      default: 'Get your ticket now!',
      type: 'string',
      'ui:hidden': '{{!parentFormData.showBanner}}',
      'ui:options': {
        placeholder: 'Get your ticket now!',
        type: 'textarea',
        rows: 3,
      },
    },
    bannerPaddingLeft: {
      title: 'Promo banner left padding, px',
      type: 'number',
      default: 5,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerPaddingRight: {
      title: 'Promo banner right padding, px',
      type: 'number',
      default: 5,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerPaddingVertical: {
      title: 'Promo banner vertical padding, px',
      type: 'number',
      default: 5,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerTextPaddingLeft: {
      title: 'Promo banner text left padding, px',
      type: 'number',
      default: 5,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerTextPaddingRight: {
      title: 'Promo banner text right padding, px',
      type: 'number',
      default: 5,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerImgLeft: {
      title: 'Promo banner left image',
      type: 'string',
      default: 'https://cdn.wnhub.io/54162/679234d374e0e4.83151220.svg',
      'ui:widget': 'EditorUploadWidget',
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerImgLeftHeight: {
      title: 'Promo banner left image max height, px',
      type: 'number',
      default: 40,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerImgRight: {
      title: 'Promo banner right image',
      type: 'string',
      default: 'https://cdn.wnhub.io/54155/679232eebc82e0.73492706.svg',
      'ui:widget': 'EditorUploadWidget',
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
    bannerImgRightHeight: {
      title: 'Promo banner right image max height, px',
      type: 'number',
      default: 120,
      'ui:hidden': '{{!parentFormData.showBanner}}',
    },
  },
}
