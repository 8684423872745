export const useDetectLang = (text: string): 'ru' | 'en' | undefined => {
  if (!text) return 'en'

  // English detection - Latin alphabet characters
  const englishRegex = /[a-zA-Z]/
  if (englishRegex.test(text)) return 'en'

  // Russian detection - Cyrillic characters
  const russianRegex = /[\u0400-\u04FF]/
  if (russianRegex.test(text)) return 'ru'

  // Return undefined if no specific language is detected
  return undefined
}
