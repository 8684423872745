<template>
  <div
    :id="uuid"
    class="p-6 py-10 md:p-8 md:py-14 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
      color: formData.textColor || '#414141',
      ...useEditorFontFamily(formData.textFontFamily),
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-8"
      :style="{
        textAlign: formData.titleAlign,
        fontWeight: formData.titleWeight,
        fontStyle: formData.titleStyle,
        color: formData.titleColor,
        fontSize: formData.titleSizePx,
        ...useEditorFontFamily(formData.titleFontFamily),
      }"
    >
      {{ formData.title }}
    </h2>
    <div class="grid gap-6" :class="[gridColsClass]">
      <template
        v-for="(card, index) in formData.cards"
        :key="'cards-grid' + index"
      >
        <IconsTextsItem :card="card" :link-color="formData.linkColor" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconsTextsItem from './IconsTextsItem.vue'
import { getGridColsClass } from '~/components/base/utils'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})
</script>
