import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import isEqual from 'lodash-es/isEqual'
import { FilterParams } from './params-helper'
import type { OptionInterface } from '~/components/base/controls/use-simple-input'
import {
  EventsListTypeMap,
  EventStatus,
  EventType,
  EventTypePluralNamesMap,
  EventTypeRoutesMap,
  EventRegion,
  EventSubtype,
} from '~/models/events'

import type {
  Event,
  EventsListType,
  EventLite,
  CourseLevel,
  CourseSubject,
  EventFilters,
} from '~/models/events'
import { Currency } from '~/models/tickets'

interface EventsState {
  event: Event | null
  isEventLoaded: boolean
  eventsMap: Record<number, Event>
  activeEvents: Event[] | null
  filters: EventFilters | null
}

export const useEvents = defineStore('events', () => {
  const { t } = useI18n()
  const host = useHost()
  const baseURL = useRuntimeConfig().public.gatewayApi
  const baseAdminURL = useRuntimeConfig().public.gatewayAdminApi
  const elasticURL = useRuntimeConfig().public.gatewayElastic
  const { isApp } = useDetect()
  const localeRoute = useLocaleRoute()

  const getSectionList = (eventType = EventType.Default) => {
    return computed(() => {
      const routes = []
      if (eventType === EventType.Course) {
        routes.push({
          id: 'about',
          local: t(`events.about_courses`),
          slug: 'about',
          icon: 'outline_book_open',
          route: `/${EventTypeRoutesMap[EventType.Course]}/about`,
        })
      }
      if (eventType === EventType.Course) {
        routes.push({
          id: 'business',
          local: t(`events.academy_business`),
          slug: 'business',
          icon: 'outline_briefcase',
          route: `/${EventTypeRoutesMap[EventType.Course]}/business`,
        })
      }
      routes.push({
        id: 'upcoming',
        local: t(`events.upcoming_${EventTypePluralNamesMap[eventType]}`),
        slug: 'upcoming',
        icon: 'outline_sparkles',
        route: `/${EventTypeRoutesMap[eventType]}`,
      })
      if (eventType === EventType.Course) {
        routes.push({
          id: 'free_webinars',
          local: t(`events.lectorium`),
          slug: 'free_webinars',
          icon: 'outline_user_group',
          route: `/${EventTypeRoutesMap[EventType.Course]}/webinars`,
        })
      }
      routes.push(
        ...[
          {
            id: 'my',
            local: t(`events.my_${EventTypePluralNamesMap[eventType]}`),
            slug: 'my',
            icon: 'outline_ticket',
            route: `/${EventTypeRoutesMap[eventType]}/my`,
          },
        ]
      )

      if (eventType === EventType.Default) {
        routes.push(
          ...[
            {
              id: 'past',
              local: t(`events.past_${EventTypePluralNamesMap[eventType]}`),
              slug: 'past',
              icon: 'outline_clock',
              route: `/${EventTypeRoutesMap[eventType]}/past`,
            },
            {
              id: 'about_events',
              local: t(`events.about_events`),
              slug: 'about_events',
              icon: 'outline_information_circle',
              route: `/${EventTypeRoutesMap[EventType.Default]}/about`,
            },
          ]
        )
      }

      return routes
    })
  }

  const state = reactive<EventsState>({
    event: null,
    isEventLoaded: false,
    eventsMap: {},
    activeEvents: null,
    filters: null,
  })

  const initEventsList = (
    listType: EventsListType = EventsListTypeMap.Me,
    eventType = EventType.Default
  ) => {
    const path = `/events/${listType}`
    const list = useInfinityList<Event>(path, baseURL)

    list.state.params = {
      per_page: 12,
      [FilterParams.EventType]: eventType,
    }
    return list
  }

  const eventsList = initEventsList() // init on start to avoid SSR state dehydration issues

  const fetchEventsFilters = async () => {
    const { data } = await useAuthFetch<{ data: EventFilters }>(
      eventsList.state.endpoint + '/filter',
      {
        baseURL: elasticURL,
        params: {
          'filter[type]': eventsList.state.params[FilterParams.EventType],
          ...(eventsList.state.params[FilterParams.EventSubtype] !==
            undefined && {
            'filter[event_type][]':
              eventsList.state.params[FilterParams.EventSubtype],
          }),
          ...(Array.isArray(
            eventsList.state.params[FilterParams.EventRegions]
          ) &&
            eventsList.state.params[FilterParams.EventRegions].length && {
              'filter[region][]':
                eventsList.state.params[FilterParams.EventRegions],
            }),
          ...(Array.isArray(
            eventsList.state.params[FilterParams.CourseLevels]
          ) &&
            eventsList.state.params[FilterParams.CourseLevels].length && {
              'filter[course_level][]':
                eventsList.state.params[FilterParams.CourseLevels],
            }),
          ...(Array.isArray(
            eventsList.state.params[FilterParams.CourseSubjects]
          ) &&
            eventsList.state.params[FilterParams.CourseSubjects].length && {
              'filter[course_subject][]':
                eventsList.state.params[FilterParams.CourseSubjects],
            }),
        },
      }
    )
    state.filters = data
  }

  const resetEventsListWithType = (
    listType: EventsListType,
    eventType = EventType.Default,
    eventSubtype?: EventSubtype,
    eventRegions?: EventRegion[],
    courseLevels?: CourseLevel[],
    courseSubjects?: CourseSubject[]
  ) => {
    const path = `/events/${listType}`
    if (
      path === eventsList.state.endpoint &&
      eventType === eventsList.state.params[FilterParams.EventType] &&
      eventSubtype === eventsList.state.params[FilterParams.EventSubtype] &&
      isEqual(
        eventRegions,
        eventsList.state.params[FilterParams.EventRegions]
      ) &&
      isEqual(
        courseLevels,
        eventsList.state.params[FilterParams.CourseLevels]
      ) &&
      isEqual(
        courseSubjects,
        eventsList.state.params[FilterParams.CourseSubjects]
      )
    ) {
      return
    }
    eventsList.state.endpoint = path
    eventsList.state.params[FilterParams.EventType] = eventType
    eventsList.state.params[FilterParams.EventSubtype] = eventSubtype
    eventsList.state.params[FilterParams.EventRegions] = eventRegions
    eventsList.state.params[FilterParams.CourseLevels] = courseLevels
    eventsList.state.params[FilterParams.CourseSubjects] = courseSubjects
    eventsList.reset()
  }

  // On SSR we have to ensure that all components will have event data without extra requests
  let fetchEventPromise: null | Promise<undefined> = null
  const fetchEvent = (eventId: number | string, isAuth = false) => {
    if (
      state.event?.slug === eventId ||
      String(state.event?.id) === String(eventId)
    ) {
      return
    }

    if (fetchEventPromise) {
      return fetchEventPromise
    }

    state.event = null

    state.isEventLoaded = false

    fetchEventPromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          const { data } = await useAuthFetch<{ data: Event }>(
            `/events/${isAuth ? 'me/' : ''}${eventId}`,
            {
              baseURL,
            }
          )

          state.event = data
          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          fetchEventPromise = null
          state.isEventLoaded = true
        }
      })()
    })

    return fetchEventPromise
  }

  const getEvent = async (eventId: number | string, isAuth = false) => {
    const { data } = await useAuthFetch<{ data: Event }>(
      `/events/${isAuth ? 'me/' : ''}${eventId}`,
      {
        baseURL,
      }
    )
    return data
  }

  const resetEvent = () => {
    state.event = null
  }

  const isEventGoingNow = (event: EventLite) => {
    const dateFrom = dayjs(event.date_from).tz(event.timezone)
    const dateTo = dayjs(event.date_to).tz(event.timezone)
    const now = dayjs().tz(event.timezone)

    return now.isAfter(dateFrom) && now.isBefore(dateTo)
  }

  const isEventActual = (event: EventLite) => {
    const dateTo = dayjs(event.hub_date_to || event.date_to).tz(event.timezone)
    const now = dayjs().tz(event.timezone)

    return now.isBefore(dateTo)
  }

  const isEventFuture = (event: EventLite) => {
    const dateFrom = dayjs(event.hub_date_from || event.date_from).tz(
      event.timezone
    )
    const now = dayjs().tz(event.timezone)

    return now.isBefore(dateFrom)
  }

  const isEventAvailableForPro = (event: EventLite) => {
    return isEventActual(event) && event.status === EventStatus.Open
  }

  const isHubOpen = (event?: Event | EventLite | null) => {
    if (!event) {
      return false
    }

    const hubDateFrom = dayjs(event.hub_date_from || event.date_from).tz(
      event.timezone
    )
    const hubDateTo = dayjs(event.hub_date_to || event.date_to).tz(
      event.timezone
    )
    const now = dayjs().tz(event.timezone)

    return (
      event.status === EventStatus.Open &&
      now.isAfter(hubDateFrom) &&
      now.isBefore(hubDateTo)
    )
  }

  const getEventPageRoute = (slug: string, eventType = EventType.Default) => {
    return `/${EventTypeRoutesMap[eventType]}/${slug}`
  }

  const getEventPageLink = (route: string) => {
    return `${host}${localeRoute(route)?.path}`
  }

  const getEventShareOptions = (link: string, name: string) => {
    return [
      {
        icon: 'brand_facebook_circle_color',
        link: getFacebookLink(link),
      },
      {
        icon: 'brand_twitter_circle_color',
        link: getTwitterLink(link),
      },
      {
        icon: 'brand_linkedin_circle_color',
        link: getLinkedinLink(link),
      },
      {
        icon: 'brand_telegram_circle_color',
        link: getTelegramLink(link, name),
      },
      {
        icon: 'brand_whatsapp_circle_color',
        link: getWhatsappLink(link, name),
      },
    ]
  }

  const getEventPlace = (event: Partial<EventLite>) => {
    if (event.is_onsite && (event.city || event.address)) {
      return event.city || event.address
    }

    if (event.is_onsite && event.is_online) {
      return `${t('events.online')} + ${t('events.onsite')}`
    }

    if (event.is_onsite) {
      return t('events.onsite')
    }

    if (event.is_online) {
      return t('events.online')
    }

    return ''
  }

  const getEventPlaceLink = (event: EventLite): string => {
    return (event.is_onsite && event.address && event.address_link) || ''
  }

  const getEventsByType = async (
    eventsListType: EventsListType,
    eventType?: EventType
  ) => {
    const path = `/events/${eventsListType}`

    try {
      const { data } = await useAuthFetch<{ data: Event[] }>(path, {
        baseURL,
        params: {
          // we don't have an endpoint to get events without pagination so we just use 100 to get all my events
          per_page: 100,
          'filter[type][]':
            eventType ||
            Object.values(EventType).filter((v) => !isNaN(Number(v))),
        },
      })
      return data
    } catch (error) {
      useLogError(error)
    }
  }

  const fetchActiveEvents = async (isAuth: boolean) => {
    if (state.activeEvents) {
      return
    }

    try {
      const eventsListType = isAuth
        ? EventsListTypeMap.MeTop
        : EventsListTypeMap.Top

      const events = await getEventsByType(eventsListType)
      state.activeEvents = events || null
    } catch (error) {
      useLogError(error)
    }
  }

  const resetActiveEvents = () => {
    state.activeEvents = null
  }

  const getHubLink = (slug: string) => {
    return isApp
      ? `/eventhub/index.html?event=${slug}`
      : `${host}/events/${slug}`
  }

  const addToWantToGo = async (_eventId: number) => {
    // await useKrakenFetch<{ data: SortedEventsGroup }>('someEndPoint', {
    //   baseURL,
    // })
  }

  const addToBookmarks = async (_eventId: number) => {
    // await useKrakenFetch<{ data: SortedEventsGroup }>('someEndPoint', {
    //   baseURL,
    // })
  }

  const downloadTicket = async (_eventId: number) => {
    // await useKrakenFetch<{ data: SortedEventsGroup }>('someEndPoint', {
    //   baseURL,
    // })
  }

  const fetchEventsByIds = async (
    id: number | number[],
    isAuth = true,
    eventType?: EventType,
    onlyVisible = false
  ) => {
    const ids = Array.isArray(id) ? id : [id]

    const filteredIds = ids.filter((id) => !(id in state.eventsMap))

    if (!filteredIds.length) {
      return
    }

    let { data: newEvents } = await useAuthFetch<{ data: Event[] }>(
      `/events/${isAuth ? 'me/' : ''}all`,
      {
        baseURL,
        params: {
          'filter[ids][]': filteredIds,
          'filter[type][]':
            eventType ||
            Object.values(EventType).filter((v) => !isNaN(Number(v))),
        },
      }
    )

    if (onlyVisible) {
      newEvents = newEvents.filter(
        (event) => event.is_show || event.is_has_ticket
      )
    }

    const newEventsMap = newEvents.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {} as Record<number, Event>)

    state.eventsMap = { ...state.eventsMap, ...newEventsMap }
  }

  const filtersCount = computed(() => {
    let count = 0

    const subtype = eventsList.state.params[FilterParams.EventSubtype]
    if (typeof subtype === 'number') {
      count += 1
    }

    const regions = eventsList.state.params[FilterParams.EventRegions]
    if (Array.isArray(regions) && regions.length) {
      count += 1
    }

    const level = eventsList.state.params[FilterParams.CourseLevels]
    if (Array.isArray(level) && level.length) {
      count += 1
    }

    const subject = eventsList.state.params[FilterParams.CourseSubjects]
    if (Array.isArray(subject) && subject.length) {
      count += 1
    }

    return count
  })

  async function getEventsAutocomplete(
    query: string
  ): Promise<OptionInterface[]> {
    const { data } = (await useAuthFetch('/events/autocomplete', {
      baseURL: baseAdminURL,
      params: { query },
    })) as { data: { id: number; name: string }[] }

    return data.map((item) => {
      return {
        value: item.id,
        label: `${item.name} (${item.id})`,
      }
    })
  }

  return {
    currency: computed(() => state.event?.main_currency || Currency.USD),
    event: toRef(state, 'event'),
    filters: toRef(state, 'filters'),
    isEventLoaded: toRef(state, 'isEventLoaded'),
    eventsMap: toRef(state, 'eventsMap'),
    resetEventsMap: () => (state.eventsMap = {}),
    activeEvents: toRef(state, 'activeEvents'),
    list: eventsList.state,
    getEvents: eventsList.getPage,
    getNext: eventsList.nextPage,
    allEventsLoaded: eventsList.allRecordsLoaded,
    resetEventsListWithType,
    reset: eventsList.reset,
    getEventsAutocomplete,
    getSectionList,
    addToWantToGo,
    addToBookmarks,
    downloadTicket,
    fetchEvent,
    getEvent,
    resetEvent,
    isEventGoingNow,
    isEventActual,
    isEventFuture,
    isEventAvailableForPro,
    isHubOpen,
    getEventPageRoute,
    getEventPageLink,
    getEventShareOptions,
    getEventPlace,
    getEventPlaceLink,
    getEventsByType,
    getHubLink,
    fetchEventsByIds,
    fetchActiveEvents,
    resetActiveEvents,
    fetchEventsFilters,
    filtersCount,
  }
})
