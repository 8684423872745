<template>
  <div
    class="flex flex-col items-center gap-2 border-2 border-gray-100 rounded-xl p-4 sm:p-6 cursor-pointer relative"
    :class="[selected ? 'border-primary-600' : 'border-gray-100']"
  >
    <div v-if="isHidden" class="absolute top-0 px-2 text-xs text-red-500">
      visible only for admins
    </div>
    <div class="text-lg font-bold">
      {{ title }}
    </div>
    <div class="flex gap-1 flex-wrap justify-center">
      <div class="whitespace-nowrap font-semibold">
        {{
          formatPrice(price.amount / 100, price.currency, languageMap[locale])
        }}
      </div>
      <div v-if="description" class="text-gray-300 whitespace-nowrap">
        ({{ description }})
      </div>
    </div>
    <div class="text-gray-300 italic font-semibold">
      {{ $t('tickets.vat') }}
    </div>
    <!-- <div class="text-xs text-gray-300 pt-1">
      {{ $t('subscriptions.month_description') }}
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { PlanPrice } from '~/models/subscriptions'
import { useTickets } from '~/stores/tickets'
import { languageMap } from '~/models/locale'

const { formatPrice } = useTickets()
const { t, locale } = useI18n()

interface PropsInterface {
  selected: boolean
  title: string
  price: PlanPrice
  period: number
  isHidden?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  selected: false,
})

// subscription prices are in cents, so we have to divide by 100
const description = computed(() => {
  return props.period > 1
    ? `${formatPrice(
        props.price.amount / props.period / 100,
        props.price.currency,
        languageMap[locale.value]
      )} / ${t('subscriptions.month')}`
    : ''
})
</script>
