<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-6"
      :style="{
        textAlign: formData.titleAlign,
        color: formData.titleColor,
        fontWeight: formData.titleWeight,
        fontStyle: formData.titleStyle,
        fontSize: formData.titleSizePx,
        ...useEditorFontFamily(formData.titleFontFamily),
      }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="grid gap-6"
      :class="[
        gridColsClass,
        formData.reviews.length > 1 && 'max-md:hidden',
        formData.reviews.length > 2 && 'hidden',
      ]"
    >
      <template
        v-for="(review, index) in formData.reviews"
        :key="'review-grid' + review.title + index"
      >
        <ReviewsCard
          :review="review"
          :user-info-color="formData.userInfoColor"
          :text-color="formData.textColor"
          :text-font-family="formData.textFontFamily"
          :bg-color="formData.cardBgColor"
          :link-color="formData.linkColor"
        />
      </template>
    </div>
    <div
      :class="[
        formData.reviews.length === 1 && 'hidden',
        formData.reviews.length === 2 && 'md:hidden',
      ]"
    >
      <Swiper
        :slides-per-view="'auto'"
        :space-between="SLIDES_GAP"
        :pagination="{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 10,
        }"
        :modules="swiperModules"
        class="swiper-custom"
      >
        <template
          v-for="(review, index) in formData.reviews"
          :key="'review-slide' + review.name + index"
        >
          <!-- can't use getSlideWidth with tailwind endpoints -->
          <SwiperSlide
            class="!w-[calc(100%-64px)] md:!w-[calc(50%-44px)]"
            :style="{
              marginRight: SLIDES_GAP + 'px',
            }"
          >
            <ReviewsCard
              :review="review"
              :user-info-color="formData.userInfoColor"
              :text-color="formData.textColor"
              :text-font-family="formData.textFontFamily"
              :bg-color="formData.cardBgColor"
              :link-color="formData.linkColor"
            />
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import ReviewsCard from './ReviewsCard.vue'
import { getGridColsClass } from '~/components/base/utils'
import { usePageEditor } from '~/stores/page-editor'

const Swiper = (await import('swiper/vue')).Swiper
const SwiperSlide = (await import('swiper/vue')).SwiperSlide
const Pagination = (await import('swiper/modules')).Pagination

const pageEditorStore = usePageEditor()

defineProps({
  uuid: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  isInIframe: {
    type: Boolean,
    default: false,
  },
})

const SLIDES_GAP = 24

const swiperModules = [Pagination]

const gridColsClass = computed(() => {
  return getGridColsClass(2, 1)
})
</script>
<style>
@import 'swiper/css';
@import 'swiper/css/pagination';
</style>
