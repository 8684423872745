import { defineStore } from 'pinia'
import { App } from '@capacitor/app'
import { useI18n } from 'vue-i18n'
import { Browser } from '@capacitor/browser'
import { type ChatMessage, type ChatChannel } from '~/models/chat'
import { NotificationAction } from '~/models/notification-list'

// import { useChat } from '~/stores/chat'
// import { useNotifications } from '~/stores/notifications'

export const useNotificationList = defineStore('notification-list', () => {
  const { $dayjs } = useNuxtApp()
  const baseURL = useRuntimeConfig().public.gatewayApi
  const { wnhubPrefix, wnhubEventPrefix } = useRuntimeConfig().public
  const { locale } = useI18n()
  const authStore = useAuth()
  // const chatStore = useChat()
  // const { pushNotification } = useNotifications()

  const notificationsList = initList()
  const notificationsPageList = initList()
  const channel = ref<null | ChatChannel>(null)

  // TODO: uncomment on complete chat integration
  // watch(
  //   () => chatStore.socket,
  //   () => {
  //     console.log('notifications: watch socket', chatStore.socket)
  //     if (chatStore.socket) {
  //       console.log('notifications: set handler')
  //       chatStore.socket.on('recieve', async (data: ChatMessage) => {
  //         if (data.channel_access !== ChannelType.Notifications) {
  //           return
  //         }
  //         console.log('notifications: receive', data)
  //         if (channel.value) {
  //           channel.value.unread_messages++
  //         } else {
  //           await updateNotificationChannel()
  //         }

  //         pushNotification({
  //           title: getNotificationLocalText(data),
  //           theme: 'info',
  //           icon: 'outline_bell',
  //           clickHandler: () => onNotificationClick(data),
  //         })

  //         notificationsList.reset()
  //         await notificationsList.getLastPage()
  //       })
  //     }
  //   },
  //   { immediate: true }
  // )

  function initList() {
    const list = useInfinityList<ChatMessage>(
      '/chat/notifications/messages',
      baseURL
    )

    list.state.params = {
      per_page: 15,
    }

    return list
  }

  const UPDATE_INTERVAL = 60000
  let updateTimer: number | undefined

  async function updateNotifications() {
    console.log('notifications: update notifications')

    const lastMessageId = channel.value?.uuid
    await updateNotificationChannel()

    if (lastMessageId !== channel.value?.uuid) {
      notificationsList.reset()
      await notificationsList.getLastPage()
    }
  }

  function initTimer() {
    if (updateTimer) {
      return
    }
    updateTimer = setInterval(async () => {
      await updateNotifications()
    }, UPDATE_INTERVAL) as unknown as number
  }

  if (process.client) {
    initTimer()
    App.addListener('pause', () => {
      clearInterval(updateTimer)
      updateTimer = undefined
    })
    App.addListener('resume', () => {
      initTimer()
    })
  }

  async function readNotifications(): Promise<void> {
    if (channel.value) {
      if (channel.value.unread_messages === 0) {
        return
      }
      channel.value.unread_messages = 0
    }
    await useAuthFetch('/chat/notifications/markasread', {
      baseURL,
      method: 'POST',
    })

    console.log('notifications: read')
  }

  let getLastPagePromise: null | Promise<undefined> = null
  function getLastPage() {
    if (getLastPagePromise) {
      return getLastPagePromise
    }

    getLastPagePromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          await notificationsList.getLastPage()
          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          getLastPagePromise = null
        }
      })()
    })

    return getLastPagePromise
  }

  let updateChannelPromise: null | Promise<undefined> = null
  function updateNotificationChannel() {
    if (updateChannelPromise) {
      return updateChannelPromise
    }

    if (process.server && channel.value) {
      return
    }

    if (!authStore.auth) {
      return
    }

    updateChannelPromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          channel.value = await useAuthFetch<ChatChannel>(
            '/chat/notifications',
            {
              baseURL,
            }
          )
          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          updateChannelPromise = null
        }
      })()
    })

    return updateChannelPromise
  }

  function getNotificationActionPath(notification: ChatMessage) {
    try {
      const data = JSON.parse(notification.custom_data)
      const {
        action,
        url,
        event_id: eventId,
        entity_id: entityId,
        // entity_type: entityType,
      } = data

      if (url) {
        return url
      }

      // TODO: refactor once we get final notification v2 system
      if (!eventId || !action) {
        console.log('notifications: no event or action on click')
        return ''
      }

      const basePath = `/events/${eventId}`

      switch (action) {
        case NotificationAction.NewMeetingRequestV2:
        case NotificationAction.MeetingConfirmedV2:
        case NotificationAction.MeetingCanceledV2:
        case NotificationAction.MeetingRescheduledV2:
        case NotificationAction.MeetingReminderV2:
          return `${basePath}/calendar/${entityId}`
        default:
          return basePath
      }
    } catch (error: any) {
      useLogError(new Error('Failed to parse notification custom data'))
      return ''
    }
  }

  function onNotificationClick(notification: ChatMessage) {
    console.log('notifications: click', notification)

    const path = getNotificationActionPath(notification)
    // it could be full path with domain from url field, or just internal path

    if (!path) {
      return
    }

    console.log('notification: open path', path)

    if (path.startsWith(wnhubEventPrefix)) {
      openEventPath(path.split(wnhubPrefix)[1])
    } else if (path.startsWith(wnhubPrefix)) {
      openGeneralPath(path.split(wnhubPrefix)[1])
    } else if (path.startsWith('/events/')) {
      openEventPath(path)
    } else if (path.startsWith('/')) {
      openGeneralPath(path)
    } else if (path.startsWith('http')) {
      openExternalLink(path)
    }
  }

  function openExternalLink(url: string) {
    if (useDetect().isApp) {
      Browser.open({ url })
    } else {
      window.open(url, '_blank')
    }
  }

  function openGeneralPath(path: string) {
    if (useDetect().isApp) {
      window.open(path, '_self')
    } else {
      window.location.href = useHost() + path
    }
  }

  function openEventPath(path: string) {
    if (useDetect().isApp) {
      window.open(`/eventhub/index.html?redirect=${path}`, '_self')
    } else {
      window.location.href = useHost() + path
    }
  }

  function getNotificationRelativeTime(message: ChatMessage) {
    const date = message.created_at
    if (!date) {
      return undefined
    }

    if ($dayjs().subtract(1, 'day') < $dayjs(date)) {
      return $dayjs(date).fromNow()
    } else {
      return $dayjs(date).format('DD.MM.YYYY HH:mm')
    }
  }

  function getNotificationLocalText(message: ChatMessage) {
    if (!message.text) {
      return ''
    }

    const parsedData = parseFieldLocales(message.text)
    return getFieldLocale(parsedData || '', locale.value)
  }

  function isCustomPushMessage(message: ChatMessage) {
    try {
      return (
        JSON.parse(message.custom_data).action === NotificationAction.CustomPush
      )
    } catch (e: any) {
      return false
    }
  }

  function getCustomPushTitle(message: ChatMessage) {
    try {
      return JSON.parse(message.custom_data).title
    } catch (e: any) {
      return ''
    }
  }

  function getCustomPushText(message: ChatMessage) {
    try {
      return JSON.parse(message.custom_data).text
    } catch (e: any) {
      return ''
    }
  }

  return {
    isCustomPushMessage,
    getCustomPushTitle,
    getCustomPushText,
    getNotificationActionPath,
    onNotificationClick,
    updateNotificationChannel,
    readNotifications,
    updateNotifications,
    channel,
    count: computed(() => channel.value?.unread_messages || 0),
    list: notificationsList.state,
    fetch: notificationsList.fetch,
    getPage: notificationsList.getPage,
    getLastPage,
    nextPage: notificationsList.nextPage,
    prevPage: notificationsList.prevPage,
    reset: notificationsList.reset,
    notificationsPageList: notificationsPageList.state,
    fetchNotificationsPageList: notificationsPageList.fetch,
    getNotificationsPageListPage: notificationsPageList.getPage,
    getNotificationsPageListLastPage: notificationsPageList.getLastPage,
    nextNotificationsPageListPage: notificationsPageList.nextPage,
    prevNotificationsPageListPage: notificationsPageList.prevPage,
    resetNotificationsPageList: notificationsPageList.reset,
    getNotificationRelativeTime,
    getNotificationLocalText,
  }
})
