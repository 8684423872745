import { useAuth } from '~/stores/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const profileRoute = '/users/me/edit/basic'
  const authRoute = '/auth/'
  const authStore = useAuth()
  const { isApp } = useDetect()
  const isNativeApp = process.client && isApp

  // TODO: add documentation about this code, why we need to redirect to meeting system
  // guess: we need to redirect to meeting system here when app on background and user clicks on push notification
  // or in case of incorrect button setup in constructor
  if (isNativeApp && to.fullPath.startsWith('/events/')) {
    console.log('meeting system path, redirect!', to.fullPath)
    window.open(`/eventhub/index.html?redirect=${to.fullPath}`, '_self')
    throw useCreateScreenError({
      message: 'Failed to navigate meeting system path on general',
    })
  }

  // here is crucial to load authCookie before checking the Auth as it is the entry point for the App
  isNativeApp && (await useAuthCookie().load())

  // TODO: add to issues, middleware runs before any components, even app.vue, so it is crucial moment here to load profile for app
  if (authStore.auth && !authStore.profile) {
    try {
      await authStore.fetchProfile()
    } catch (error: any) {
      if (error.status === 401) {
        useLogError(new Error('Failed to fetch profile (invalid auth cookie)'))
        authStore.logout()
      } else {
        // for some reason we don't get throwed error in any error handler during SSR
        // so we directly logging it with throw next to render error page
        useLogError(error, true)
      }
    }
  }

  if (
    !to.fullPath.includes(authRoute) &&
    !to.fullPath.includes(profileRoute) &&
    !authStore.isProfileCompleted &&
    authStore.auth &&
    authStore.profile
  ) {
    return navigateTo(
      useLocaleRoute()({
        path: profileRoute,
        query: {
          redirect: from.query.redirect
            ? encodeURI(from.query.redirect as string)
            : encodeURI(from.fullPath),
        },
      })
    )
  }
})
