import { DEFAULT_MARKDOWN_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  linkColorSchema,
  shadowSchema,
  showAllSettingsSchema,
  textColorSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Markdown',
  description: 'Markdown text block with background',
  type: 'object',
  required: ['text'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['textColor', 'bgColor', 'linkColor', 'blockShadow'],
  },
  properties: {
    ...basePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...linkColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_TEXT,
        type: 'textarea',
        rows: 6,
      },
    },
  },
}
