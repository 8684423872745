import { defineStore } from 'pinia'
import { useAuth } from './auth'
import type {
  Plan,
  Subscription,
  SubscriptionTicket,
} from '~/models/subscriptions'
import { Currency } from '~/models/tickets'
import type { UserProfile } from '~/models/users'

interface SubscriptionsState {
  plans: Plan[]
  subscription: Subscription | null
  tickets: SubscriptionTicket[]
}

export const useSubscriptions = defineStore('subscriptions', () => {
  const authStore = useAuth()

  const baseURL = useRuntimeConfig().public.gatewayApi

  const state = reactive<SubscriptionsState>({
    plans: [],
    subscription: null,
    tickets: [],
  })

  async function fetchPlans() {
    const { data } = await useKrakenFetch<{ data: Plan[] }>(
      'subscription-plans',
      {
        baseURL,
      }
    )

    return data
  }

  async function fetchAllPlans() {
    const { data } = await useAuthFetch<{ data: Plan[] }>(
      'subscription-plans/all',
      {
        baseURL,
      }
    )

    return data
  }

  async function fetchPlansList(isAdmin = false) {
    if (state.plans.length) {
      return
    }

    const data = isAdmin ? await fetchAllPlans() : await fetchPlans()

    state.plans = data.map((plan) => {
      plan.name = parseFieldLocales(plan.name as string) as
        | string
        | Record<string, string>
      plan.description = parseFieldLocales(plan.description as string) as
        | string
        | Record<string, string>
      return plan
    })
  }

  async function buySubscription(planId: number, currency: Currency) {
    const { data } = await useAuthFetch<{ data: { redirect_url: string } }>(
      'subscriptions/buy',
      {
        baseURL,
        method: 'POST',
        body: {
          subscription_plan_id: planId,
          currency,
        },
      }
    )

    return data.redirect_url
  }

  async function fetchActiveSubscription(reload = false) {
    if (state.subscription && !reload) {
      return
    }

    const { data } = await useAuthFetch<{ data: Subscription }>(
      'subscriptions/active',
      {
        baseURL,
      }
    )

    state.subscription = data
  }

  function resetSubscription() {
    state.subscription = null
  }

  async function unsubscribe(reason: string, message = '') {
    await useAuthFetch('subscriptions/unsubscribe', {
      baseURL,
      method: 'PATCH',
      body: {
        cancel_reason: reason,
        cancel_reason_message: message,
      },
    })
  }

  async function fetchSubscriptionTickets() {
    if (state.tickets.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: SubscriptionTicket[] }>(
      'subscription-items/me',
      {
        baseURL,
      }
    )

    state.tickets = data
  }

  async function activateSubscriptionTicket(
    subscriptionTicketId: number,
    memberData: Partial<UserProfile>
  ) {
    await useAuthFetch(`subscription-items/${subscriptionTicketId}/activate`, {
      baseURL,
      method: 'POST',
      body: memberData,
    })
  }

  const resetPlans = () => {
    state.plans = []
  }

  const resetSubscriptionTickets = () => {
    state.tickets = []
  }

  // we reset subscription on logout in store because watcher doesn't work for pages inside WithAuth
  watch(
    () => !authStore.auth,
    () => {
      resetSubscription()
      resetSubscriptionTickets()
    }
  )

  return {
    plans: toRef(state, 'plans'),
    subscription: toRef(state, 'subscription'),
    tickets: toRef(state, 'tickets'),
    fetchPlansList,
    buySubscription,
    fetchActiveSubscription,
    resetSubscription,
    unsubscribe,
    fetchSubscriptionTickets,
    activateSubscriptionTicket,
    resetPlans,
    resetSubscriptionTickets,
  }
})
