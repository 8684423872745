import { markRaw } from 'vue'
import SubscriptionView from './SubscriptionView.vue'
import schema from './schema'

export default {
  viewComponent: markRaw(SubscriptionView),
  editorSchema: schema,
  editorData: {
    disclaimerText:
      'After subscribing, your credit/debit card will be billed each month with the same amount. Your subscription begins on **{{startDate}} and will end on {{endDate}}**. You can cancel anytime before {{endDate}} to avoid being charged. A month is always considered to be 30 days.\n\nPlease note that if you cancel within the **first three months**, you will not be able to reactivate your membership for the next **six months**. This ensures that WN Pro remains an exclusive and committed community.',
  },
}
