<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <template v-if="!isShowPopup">
      <h2
        v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
        class="text-2xl md:text-3xl font-bold mb-6"
        :style="{
          textAlign: formData.titleAlign,
          color: formData.mainTitleColor,
          fontWeight: formData.titleWeight,
          fontStyle: formData.titleStyle,
          fontSize: formData.titleSizePx,
          ...useEditorFontFamily(formData.titleFontFamily),
        }"
      >
        {{ formData.title }}
      </h2>
      <div :class="formData.isUseSliderOnMobile ? 'hidden md:block' : 'block'">
        <div class="grid gap-x-6 gap-y-10" :class="gridColsClass">
          <template
            v-for="(speaker, index) in isShowAll
              ? formData.speakers
              : formData.speakers.slice(0, formData.visibleNumber)"
            :key="'speakers-grid' + speaker.title + index"
          >
            <SpeakersCard
              :speaker="speaker"
              :title-color="formData.titleColor"
              :title-font-family="formData.cardTitleFontFamily"
              :subtitle-font-family="formData.cardSubtitleFontFamily"
              :subtitle-color="formData.subtitleColor"
              :link-color="formData.linkColor"
              @click="onSpeakerClick(index)"
            />
          </template>
        </div>
        <div
          v-if="
            !isShowAll &&
            formData.visibleNumber &&
            formData.speakers.length > formData.visibleNumber
          "
          class="flex justify-center mt-10"
        >
          <BaseButton @click="onShowAll">
            {{ $t('common.show_all') }}
          </BaseButton>
        </div>
      </div>
      <div :class="formData.isUseSliderOnMobile ? 'block md:hidden' : 'hidden'">
        <Swiper
          :slides-per-view="'auto'"
          :space-between="SLIDES_GAP"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 10,
          }"
          :modules="swiperModules"
          class="swiper-custom"
        >
          <template
            v-for="(speaker, index) in formData.speakers"
            :key="'speakers-slide' + speaker.title + index"
          >
            <SwiperSlide
              :style="{
                marginRight: SLIDES_GAP + 'px',
                width: getSlideWidth(formData.colsNumberOnMobile, SLIDES_GAP),
              }"
            >
              <SpeakersCard
                :speaker="speaker"
                :title-color="formData.titleColor"
                :subtitle-color="formData.subtitleColor"
                :title-font-family="formData.cardTitleFontFamily"
                :subtitle-font-family="formData.cardSubtitleFontFamily"
                :link-color="formData.linkColor"
                @click="onSpeakerClick(index)"
              />
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </template>
    <SpeakersModal
      :speaker="
        isShowPopup
          ? formData.speakers[popupSpeakerId]
          : formData.speakers[selectedSpeakerIndex]
      "
      :is-open="isModalOpen || isShowPopup"
      :title-color="formData.modalTitleColor"
      :subtitle-color="formData.modalSubtitleColor"
      :text-color="formData.textColor"
      :title-font-family="formData.cardTitleFontFamily"
      :subtitle-font-family="formData.cardSubtitleFontFamily"
      :text-font-family="formData.modalTextFontFamily"
      :bg-color="formData.modalBgColor"
      :bg-class="isShowPopup ? '' : undefined"
      :link-color="formData.linkColor"
      @close="onPopupClose"
    />
  </div>
</template>

<script setup lang="ts">
import SpeakersCard from './SpeakersCard.vue'
import SpeakersModal from './SpeakersModal.vue'
import { getGridColsClass, getSlideWidth } from '~/components/base/utils'
import { usePageEditor } from '~/stores/page-editor'
const Swiper = (await import('swiper/vue')).Swiper
const SwiperSlide = (await import('swiper/vue')).SwiperSlide
const Pagination = (await import('swiper/modules')).Pagination

const pageEditorStore = usePageEditor()

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  isInIframe: {
    type: Boolean,
    default: false,
  },
})

const SLIDES_GAP = 24
const popupSpeakerId = Number(useRoute().query.id)
const { sendData } = useFrameBridge()
const isShowPopup =
  popupSpeakerId !== undefined &&
  props.formData.speakers[popupSpeakerId] !== undefined

const emit = defineEmits(['update', 'close', 'open'])
const isShowAll = ref(false)
const isModalOpen = ref(false)
const selectedSpeakerIndex = ref(0)
const swiperModules = [Pagination]

function onPopupClose() {
  if (props.isInIframe) {
    sendData({
      message_type: 'close_popup',
    })
  }
  isModalOpen.value = false
}

function onShowAll() {
  isShowAll.value = true
  nextTick(() => {
    emit('update')
  })
}

const onSpeakerClick = (idx: number) => {
  if (props.isInIframe) {
    sendData({
      message_type: 'open_popup',
      url: `${location.href}?id=${idx}`,
    })

    return
  }
  selectedSpeakerIndex.value = idx
  isModalOpen.value = true
}

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})
</script>
<style>
@import 'swiper/css';
@import 'swiper/css/pagination';
</style>
