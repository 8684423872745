<template>
  <BaseModal :is-open="isOpen" size="xl" @close="close">
    <template #header>
      <div class="flex justify-between px-6 pt-6">
        <div class="text-xl font-bold w-3/4">
          {{ $t('subscriptions.cancel_modal_title') }}
        </div>
        <BaseButton theme="white" size="sm" class="w-8 !p-0" @click="close">
          <BaseIcon name="outline_x" />
        </BaseButton>
      </div>
    </template>
    <div class="flex flex-col gap-6">
      <!-- <div class="flex flex-col gap-2">
        <div>
          {{ $t('subscriptions.cancel_modal_features_title') }}
        </div>
        <ul class="list-disc pl-6">
          <li>
            {{ $t('subscriptions.cancel_modal_features_1') }}
          </li>
          <li>
            {{ $t('subscriptions.cancel_modal_features_2') }}
          </li>
        </ul>
      </div> -->
      <BaseRadioGroup
        v-model="form.reason"
        :label="$t('subscriptions.cancel_modal_reasons_title')"
        :options="cancelReasonsOptions"
      />
      <BaseTextarea
        v-if="form.reason === cancelReasonsOptions.length - 1"
        v-model="form.message"
        :placeholder="$t('comments.new_comment_placeholder')"
        name="text"
        rows="4"
      />
      <div class="flex justify-between">
        <BaseButton look="link" @click="close">
          {{ $t('subscriptions.cancel') }}
        </BaseButton>
        <BaseButton
          :loading="isProcessing"
          :disabled="isProcessing"
          @click="submit"
        >
          {{ $t('subscriptions.unsubscribe') }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSubscriptions } from '~/stores/subscriptions'
import { useNotifications } from '~/stores/notifications'

interface PropsInterface {
  isOpen: boolean
}

withDefaults(defineProps<PropsInterface>(), {
  isOpen: false,
})

const emit = defineEmits(['close', 'unsubscribe'])

interface CancelModalForm {
  reason: number
  message?: string
}

const form = reactive<CancelModalForm>({
  reason: 0,
  message: '',
})

const { t } = useI18n()
const { unsubscribe } = useSubscriptions()
const { pushNotification } = useNotifications()

const isProcessing = ref(false)

const close = () => {
  emit('close')
}

const resetForm = () => {
  form.reason = 0
  form.message = ''
}

const cancelReasons = [
  t('subscriptions.cancel_modal_reasons_1'),
  t('subscriptions.cancel_modal_reasons_2'),
  t('subscriptions.cancel_modal_reasons_3'),
  t('subscriptions.cancel_modal_reasons_4'),
]

const cancelReasonsOptions = cancelReasons.map((reason, index) => ({
  label: reason,
  value: index,
}))

const submit = async () => {
  isProcessing.value = true
  try {
    await unsubscribe(cancelReasons[form.reason], form.message)
    emit('unsubscribe')
    close()
    resetForm()
    pushNotification({
      title: t('notifications.subscriptions.unsubscribe_success'),
    })
  } catch (error) {
    pushNotification({
      title: t('notifications.subscriptions.unsubscribe_error'),
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isProcessing.value = false
  }
}
</script>
