import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  linkColorSchema,
  shadowSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
  textFontFamilySchema,
} from '../common'
import { DEFAULT_MARKDOWN_TEXT } from '../constants'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Video',
  description: 'Video block with title and markdown description',
  type: 'object',
  required: ['sourse', 'videoId', 'videoUrl'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['textColor', 'linkColor', 'bgColor', 'blockShadow'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...linkColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    description: {
      title: 'Description',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_TEXT,
        type: 'textarea',
        rows: 6,
      },
    },
    maxWidth: {
      title: 'Max width in pixels',
      type: 'number',
    },
    source: {
      title: 'Source',
      type: 'string',
      default: 'youtube',
      enum: ['youtube', 'gcore', 'googledrive'],
      enumNames: ['Youtube', 'G-Core', 'Google Drive'],
    },
    videoId: {
      title: 'Video id',
      type: 'string',
      'ui:hidden': '{{!parentFormData.videoId}}',
    },
    videoUrl: {
      title: 'Video url',
      type: 'string',
      minLength: 20,
      'ui:hidden': '{{!!parentFormData.videoId}}',
      default:
        'https://www.youtube.com/embed/vb8NvZkck5c?origin=https://wnhub.io',
    },
  },
}
