import { basePropertiesSchema } from '../base-schema-properties'
import {
  showAllSettingsSchema,
  titlePropertiesSchema,
  bgColorSchema,
  linkColorSchema,
  textColorSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Icons + Texts',
  description: 'Block with cards that contain icons and texts',
  type: 'object',
  required: ['colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'colsNumberOnDesktop',
      'colsNumberOnMobile',
      'bgColor',
      'linkColor',
      'textColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...bgColorSchema,
    ...textColorSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...linkColorSchema,
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 4,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    cards: {
      type: 'array',
      title: 'A list of cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Card',
        type: 'object',
        properties: {
          icon: {
            title: 'Icon',
            type: 'string',
            default: '',
            'ui:widget': 'EditorUploadWidget',
          },
          text: {
            title: 'Text',
            type: 'string',
            default: '',
            'ui:widget': 'EditorMarkdownWidget',
          },
        },
      },
    },
  },
}
