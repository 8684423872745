<template>
  <BaseLink
    :href="isRelativeLink(link) ? undefined : linkUrl"
    :to="isRelativeLink(link) ? linkUrl : undefined"
    :target="getLinkTarget(link, isBlank)"
    theme="none"
    class="hover:opacity-80"
  >
    <div
      class="bg-white flex gap-2 xl:gap-1.5 flex-col max-w-full xl:flex-row xl:h-[80px] items-center justify-between flex-1 xl:flex-nowrap p-3 xl:p-4 xl:pl-3 xl:pr-2.5 rounded-2xl aspect-square xl:aspect-auto"
      :class="shadow && 'shadow-lg'"
    >
      <div
        class="xl:w-[50px] xl:h-full xl:shrink-0 shrink xl:order-last w-[60%] flex-grow xl:flex-grow-0 flex items-center justify-center bg-no-repeat bg-contain bg-center"
        :style="`background-image: url(${imageUrl})`"
      />
      <div
        :lang="useDetectLang(title || '')"
        class="text-md w-full xl:w-auto lg:text-lg font-bold !leading-tight line-clamp-2 hyphens-auto md:line-clamp-3 break-words text-center xl:text-left"
        :style="{
          color: textColor,
          fontWeight: fontWeight,
          fontStyle: fontStyle,
          fontSize: fontSize,
          ...useEditorFontFamily(fontFamily),
        }"
        v-html="safeTitle"
      ></div>
    </div>
  </BaseLink>
</template>

<script setup lang="ts">
import DOMPurify from 'isomorphic-dompurify'

interface PropsInterface {
  link?: string
  isBlank?: boolean
  shadow?: boolean
  imageUrl?: string
  title?: string
  fontWeight?: string
  fontStyle?: string
  textColor?: string
  fontFamily?: string
  fontSize?: string
}

const props = defineProps<PropsInterface>()

const { getLinkTarget, getLinkUrl, isRelativeLink } = useEditorLinks()

const linkUrl = computed(() => getLinkUrl(props.link))

const safeTitle = computed(() => {
  if (!props.title) return ''

  // Sanitize the title to prevent XSS attacks
  // Remove all HTML tags but wbr, preserve HTML entities like &shy;
  return DOMPurify.sanitize(props.title, {
    ALLOWED_TAGS: ['wbr'],
  })
})
</script>
