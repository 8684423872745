<template>
  <div
    class="rounded-xl overflow-hidden h-full"
    :class="isBordered && 'border'"
    :style="{
      borderColor: borderColor || '#414141',
      boxShadow: isShadowed
        ? `1px 3px 5px ${hexToRgba(shadowColor || '#414141', 0.2)}`
        : '',
    }"
  >
    <div v-if="isNotValid" class="text-red-700 px-4 py-2">
      Please, don't use links in markdown text if you have the link on the card
    </div>
    <BaseLink
      v-else-if="card.link && linkUrl"
      :href="isRelativeLink(card.link) ? undefined : linkUrl"
      :to="isRelativeLink(card.link) ? linkUrl : undefined"
      :target="getLinkTarget(card.link, card.isBlank)"
      class="!block w-full h-full"
    >
      <div
        v-if="card.cardType === 'text'"
        class="marked px-4 py-2 h-full"
        :style="{
          color: card.textColor || '#414141',
          backgroundColor: card.bgColor || '',
          '--text-color': card.textColor,
          ...useEditorFontFamily(card.textFontFamily),
        }"
        v-html="markedText"
      />
      <BaseImage
        v-else-if="card.imageUrl"
        :src="card.imageUrl"
        loading="lazy"
        class="object-cover w-full h-full"
        :style="{
          backgroundColor: card.bgColor || '',
        }"
      />
    </BaseLink>
    <template v-else>
      <div
        v-if="card.cardType === 'text'"
        class="marked px-4 py-2 h-full"
        :style="{
          color: card.textColor || '#414141',
          backgroundColor: card.bgColor || '',
          '--text-color': card.textColor,
          ...useEditorFontFamily(card.textFontFamily),
        }"
        v-html="markedText"
      />
      <BaseImage
        v-else-if="card.imageUrl"
        loading="lazy"
        :src="card.imageUrl"
        class="object-cover w-full h-full"
        :style="{
          backgroundColor: card.bgColor || '',
        }"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { hexToRgba } from '~/utils/color'

interface CardInterface {
  cardType: 'image' | 'text'
  imageUrl?: string
  text?: string
  textColor?: string
  textFontFamily?: string
  bgColor?: string
  link?: string
  linkColor?: string
  isBlank?: boolean
}

interface PropsInterface {
  card: CardInterface
  isShadowed: boolean
  shadowColor: string
  isBordered: boolean
  borderColor: string
}

const props = defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
const { getLinkTarget, getLinkUrl, isRelativeLink } = useEditorLinks()

const linkUrl = computed(() => getLinkUrl(props.card.link))

const markedText = computed(() =>
  props.card.cardType === 'text' && props.card.text
    ? getMarkedTextWithBlankLinks(props.card.text, props.card.linkColor)
    : ''
)

// cards with links that also contains links in markdown cause the hydration mismatch error
const isNotValid = computed(
  () => props.card.link && markedText.value.includes('<a')
)
</script>
