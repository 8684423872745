<template>
  <BaseSelect
    v-model="value"
    :options="ticketOptions"
    name="ticket"
    class="w-full"
  />
</template>

<script setup lang="ts">
import { useTickets } from '~/stores/tickets'
import { useEvents } from '~/stores/events'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()
const ticketsStore = useTickets()

const ticketOptions = computed(() => {
  return ticketsStore.ticketsDetails.map((ticket) => ({
    label: `${ticket.name}${ticket.available ? '' : ' (not available)'}`,
    value: ticket.id,
  }))
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

onMounted(async () => {
  await ticketsStore.fetchTicketListDetails(eventsStore.event?.id)
})
</script>
