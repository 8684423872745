import { DEFAULT_MARKDOWN_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  titlePropertiesSchema,
  shadowSchema,
  titleSizeSchema,
  textColorSchema,
  bgColorSchema,
  linkColorSchema,
  colsNumberOnDesktopSchema,
  buttonsPropertiesSchema,
  isBlankSchema,
  showAllSettingsSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Columns',
  description:
    'Block with a configurable number of columns with text or image (linkable or not)',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['textColor', 'bgColor', 'linkColor', 'blockShadow'],
    title: ['titleAlign', 'hideOnEmbed', 'titleSize', 'titleColor'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...titleSizeSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...linkColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    ...colsNumberOnDesktopSchema,
    columns: {
      type: 'array',
      title: 'Columns',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        type: 'object',
        minItems: 1,
        properties: {
          isImage: {
            title: 'Image column',
            type: 'boolean',
            default: false,
          },
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_TEXT,
              type: 'textarea',
              rows: 6,
            },
            'ui:widget': 'EditorMarkdownWidget',
            'ui:hidden': '{{parentFormData.isImage === true}}',
          },
          buttons: {
            ...buttonsPropertiesSchema.buttons,
            'ui:hidden': '{{parentFormData.isImage === true}}',
          },
          image: {
            title: 'Image url',
            type: 'string',
            'ui:widget': 'EditorUploadWidget',
            'ui:hidden': '{{parentFormData.isImage === false}}',
          },
          link: {
            title: 'Image link',
            type: 'string',
            'ui:hidden': '{{parentFormData.isImage === false}}',
          },
          isBlank: {
            ...isBlankSchema.isBlank,
            'ui:hidden': '{{parentFormData.isImage === false}}',
          },
        },
      },
    },
  },
}
