import { DEFAULT_IMAGE_HORIZONTAL } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  titlePropertiesSchema,
  shadowSchema,
  isBlankSchema,
  textWeightSchema,
  textStyleSchema,
  textColorSchema,
  textSizeSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Tiles',
  description: 'Block with tiles (main page menu)',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...shadowSchema,
    tiles: {
      type: 'array',
      title: 'A list of tiles',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Tiles',
        type: 'object',
        properties: {
          imageUrl: {
            title: 'Image url',
            type: 'string',
            default: DEFAULT_IMAGE_HORIZONTAL,
          },
          title: {
            title: 'Title',
            type: 'string',
            default: 'Title',
          },
          link: {
            title: 'Link',
            type: 'string',
          },
          fontWeight: textWeightSchema,
          fontStyle: textStyleSchema,
          fontSize: textSizeSchema,
          ...textColorSchema,
          ...textFontFamilySchema,
          ...isBlankSchema,
        },
      },
    },
  },
}
