<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <h2
      v-if="formData.title"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-6"
      :style="{
        textAlign: formData.titleAlign,
        fontWeight: formData.titleWeight,
        fontStyle: formData.titleStyle,
        color: formData.titleColor,
        fontSize: formData.titleSizePx,
        ...useEditorFontFamily(formData.titleFontFamily),
      }"
    >
      {{ formData.title }}
    </h2>
    <!-- <SubscriptionsSuccessBlock
      v-if="authStore.profile && isShowSuccessCard"
      :profile="authStore.profile"
    /> -->
    <div class="space-y-3 text-gray">
      <template v-if="authStore.isProActive">
        <SubscriptionsProHeader
          :subscription="subStore.subscription"
          :is-pro-active="authStore.isProActive"
        />
        <div v-if="isSubscriptionLoading" class="py-10 flex justify-center">
          <BaseSpinner />
        </div>
        <template v-else-if="subStore.subscription">
          <SubscriptionsManageBlock
            :subscription="subStore.subscription"
            @unsubscribe="onUnsubscribe"
          />
          <!-- <div v-if="isTicketsLoading" class="py-10 flex justify-center">
            <BaseSpinner />
          </div>
          <div
            v-else-if="
              subStore.tickets.length &&
              Object.values(eventsStore.eventsMap).length
            "
            class="flex flex-col gap-6 bg-white rounded-xl p-4 sm:p-6"
          >
            <div class="text-2xl text-gray-900 font-bold">
              {{ t('subscriptions.tickets_title') }}
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-12">
              <template v-for="ticket in subStore.tickets" :key="ticket.id">
                <SubscriptionsTicketCard
                  :ticket="ticket"
                  :event="eventsStore.eventsMap[ticket.event_id]"
                  @activated="onActivated"
                />
              </template>
            </div>
          </div> -->
        </template>
      </template>
      <div v-else class="flex flex-col gap-4">
        <div v-if="formData.subscriptionsTitle" class="text-xl font-bold">
          {{ formData.subscriptionsTitle }}
        </div>
        <div v-if="isPlansLoading" class="py-10 flex justify-center">
          <BaseSpinner />
        </div>
        <template v-else-if="subStore.plans.length">
          <div
            class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-3"
          >
            <template v-for="(plan, index) in subStore.plans" :key="plan.id">
              <SubscriptionsPlanCard
                :selected="index === selectedPlanIndex"
                :title="getFieldLocale(plan.name, i18n.locale.value)"
                :price="plan.prices[0]"
                :period="plan.period"
                :is-hidden="!plan.is_onsite"
                @click="selectedPlanIndex = index"
              />
            </template>
          </div>
          <div
            v-if="disclaimerHtml"
            class="bg-gray-100/50 p-4 rounded-xl marked"
            v-html="disclaimerHtml"
          ></div>
          <div class="flex flex-col items-center justify-center">
            <BaseButton
              class="w-full md:w-auto"
              :loading="isPaymentLinkLoading"
              :disabled="isPaymentLinkLoading"
              @click="submit"
            >
              {{ t('common.subscribe') }}
            </BaseButton>
          </div>
        </template>
        <div v-else>
          {{ $t('subscriptions.no_plans') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Browser } from '@capacitor/browser'
import { useAuth } from '~/stores/auth'
import { useSubscriptions } from '~/stores/subscriptions'
import { useNotifications } from '~/stores/notifications'
import { useTickets } from '~/stores/tickets'
import { usePageEditor } from '~/stores/page-editor'
// import { SubscriptionTicketStatus } from '~/models/subscriptions'
// import { useEvents } from '~/stores/events'

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const { getMarkedTextWithBlankLinks } = useMarkedText()
const pageEditorStore = usePageEditor()
const authStore = useAuth()
const subStore = useSubscriptions()
const {
  fetchPlansList,
  fetchActiveSubscription,
  buySubscription,
  resetSubscription,
  resetPlans,
  // fetchSubscriptionTickets,
} = subStore
// const eventsStore = useEvents()
// const router = useRouter()
// const isTicketsLoading = ref(false)
// const isShowSuccessCard = computed(() => {
//   return router.currentRoute.value.fullPath.includes('success')
// })
const { t } = useI18n()
const i18n = useI18n()
const { $dayjs } = useNuxtApp()
const { pushNotification } = useNotifications()

const { getHumanizedDate } = useDate()
const { resetPaymentsList } = useTickets()

const selectedPlanIndex = ref(0)
const isSubscriptionLoading = ref(false)

const isPlansLoading = ref(false)
const isPaymentLinkLoading = ref(false)
const paymentLink = ref<string | null>(null)
const isNavigated = ref(false)

const fetchPlans = async () => {
  if (!authStore.isProActive) {
    isPlansLoading.value = true
    await fetchPlansList(authStore.isAdmin)
    isPlansLoading.value = false
  }
}

const fetchSubscription = async () => {
  isSubscriptionLoading.value = true
  try {
    await fetchActiveSubscription()
  } catch (error: any) {
    if (error.status === 404) {
      resetSubscription()
      return
    }
    throw error
  } finally {
    isSubscriptionLoading.value = false
  }
}

const planDescriptionStartDate = computed(() => {
  const startDate = $dayjs().tz()
  return getHumanizedDate(startDate)
})

const planDescriptionEndDate = computed(() => {
  if (!subStore.plans.length) {
    return ''
  }

  const period = subStore.plans[selectedPlanIndex.value].period

  const today = $dayjs().tz()
  const endDate = today.add(period, 'month')

  return getHumanizedDate(endDate)
})

const disclaimerHtml = computed(() => {
  return getMarkedTextWithBlankLinks(props.formData.disclaimerText)
    .replace(/{{startDate}}/g, planDescriptionStartDate.value)
    .replace(/{{endDate}}/g, planDescriptionEndDate.value)
})

const submit = async () => {
  if (!authStore.auth) {
    authStore.openLoginModal()
    return
  }
  const isApp = useDetect().isApp

  isPaymentLinkLoading.value = true
  try {
    const planId = subStore.plans[selectedPlanIndex.value].id
    const currency = subStore.plans[selectedPlanIndex.value].prices[0].currency

    paymentLink.value = await buySubscription(planId, currency)

    if (isApp) {
      await Browser.open({ url: paymentLink.value })
    } else {
      window.location.href = paymentLink.value
      isNavigated.value = true
    }
    resetPaymentsList()
  } catch (error) {
    pushNotification({
      title: t('notifications.tickets.create_url_error'),
      theme: 'destructive',
    })
    throw error
  } finally {
    !isNavigated.value && (isPaymentLinkLoading.value = false)
  }
}

const onUnsubscribe = () => {
  resetSubscription()
  resetPaymentsList()
  fetchSubscription()
}

// const onActivated = (ticketId: number) => {
//   const ticket = subStore.tickets.find(({ id }) => id === ticketId)

//   if (ticket) {
//     ticket.status = SubscriptionTicketStatus.Activated
//     eventsStore.eventsMap[ticket.event_id].is_has_ticket = true
//   }
// }

const load = async () => {
  if (authStore.auth) {
    await fetchSubscription()

    // if (authStore.isProActive) {
    //   try {
    //     isTicketsLoading.value = true
    //     await fetchSubscriptionTickets()

    //     const eventIds = subStore.tickets.map((ticket) => ticket.event_id)
    //     await eventsStore.fetchEventsByIds(eventIds)
    //   } catch (error) {
    //     useLogError(error)
    //   } finally {
    //     isTicketsLoading.value = false
    //   }
    // }
  }

  await fetchPlans()
}

onServerPrefetch(async () => {
  await load()
})

onMounted(() => {
  load()
})

watch(
  () => authStore.auth,
  () => {
    resetPlans()
    selectedPlanIndex.value = 0
    if (authStore.auth) {
      load()
    } else {
      // we reset subscription and subscription tickets on logout in store, so we don't do it here again
      fetchPlans()
    }
  }
)
</script>
