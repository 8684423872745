/* eslint-disable camelcase */
import { PushNotifications } from '@capacitor/push-notifications'
import type {
  ActionPerformed,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications'
import { Browser } from '@capacitor/browser'
import { useAuth } from '~/stores/auth'
import { useNotifications } from '~/stores/notifications'

enum PushActionType {
  NewMessage = 'new_message',
  NewMeetingRequest = 'new_meeting_request',
  MeetingConfirmed = 'meeting_confirmed',
  MeetingCanceled = 'meeting_canceled',
  MeetingRescheduled = 'meeting_rescheduled',
  MeetingReminderV2 = 'meeting_reminder_v2',
  NewMeetingRequestV2 = 'new_meeting_request_v2',
  MeetingConfirmedV2 = 'meeting_confirmed_v2',
  MeetingCanceledV2 = 'meeting_canceled_v2',
  MeetingRescheduledV2 = 'meeting_rescheduled_v2',
  CustomPush = 'custom_push',
}

const themeMap: Record<PushActionType, string> = {
  [PushActionType.MeetingCanceled]: 'destructive',
  [PushActionType.MeetingConfirmed]: 'success',
  [PushActionType.MeetingRescheduled]: 'info',
  [PushActionType.NewMeetingRequest]: 'info',
  [PushActionType.MeetingCanceledV2]: 'destructive',
  [PushActionType.MeetingConfirmedV2]: 'success',
  [PushActionType.MeetingRescheduledV2]: 'info',
  [PushActionType.NewMeetingRequestV2]: 'info',
  [PushActionType.MeetingReminderV2]: 'info',
  [PushActionType.NewMessage]: 'info',
  [PushActionType.CustomPush]: 'info',
}

const iconMap: Record<PushActionType, string> = {
  [PushActionType.MeetingCanceled]: 'outline_user_group',
  [PushActionType.MeetingConfirmed]: 'outline_user_group',
  [PushActionType.MeetingRescheduled]: 'outline_user_group',
  [PushActionType.NewMeetingRequest]: 'outline_user_group',
  [PushActionType.MeetingCanceledV2]: 'outline_user_group',
  [PushActionType.MeetingConfirmedV2]: 'outline_user_group',
  [PushActionType.MeetingRescheduledV2]: 'outline_user_group',
  [PushActionType.NewMeetingRequestV2]: 'outline_user_group',
  [PushActionType.MeetingReminderV2]: 'outline_user_group',
  [PushActionType.NewMessage]: 'outline_chat_alt',
  [PushActionType.CustomPush]: 'outline_bell',
}

interface PushDataInterface {
  event_id?: number
  action?: PushActionType
  entity_id?: string | number
  path?: string
  url?: string
}

export const usePushService = () => {
  const offset = new Date().getTimezoneOffset() / 60
  const baseURL = useRuntimeConfig().public.gatewayPushApi
  const { wnhubPrefix, wnhubEventPrefix } = useRuntimeConfig().public
  const { isApp } = useDetect()
  const { platform } = useDevice()
  const appToken = useState<string | null>('appToken', () => null)

  const registerNotifications = async () => {
    if (!isApp) {
      return
    }

    try {
      let permStatus = await PushNotifications.checkPermissions()

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
        return
      }

      // create Android notification channel with high importance
      if (platform === 'android') {
        try {
          await PushNotifications.createChannel({
            id: 'default',
            name: 'Default',
            description: 'Default notifications channel',
            importance: 4,
          })
        } catch (error: any) {
          useLogError(error)
        }
      }

      await PushNotifications.register()
    } catch (error: any) {
      useLogError(error)
    }
  }

  const addListeners = () => {
    PushNotifications.addListener('registration', async (token: Token) => {
      await registerAppToken(token.value)
    })
    PushNotifications.addListener('registrationError', (error: any) => {
      useLogError(
        new Error('push notification registration error: ' + error.error)
      )
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        const { pushNotification } = useNotifications()
        const { updateNotifications } = useNotificationList()

        updateNotifications()
        pushNotification({
          title: notification.title as string,
          description: notification.body,
          theme: themeMap[notification.data.action as PushActionType],
          icon: iconMap[notification.data.action as PushActionType],
          playSound: true,
          clickHandler: () => {
            providePushData(notification.data)
          },
        })
      }
    )

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        providePushData(notification.notification.data)
      }
    )
  }

  const providePushData = (data: PushDataInterface) => {
    const { event_id, action, entity_id, path, url } = data

    console.log(
      'native: provide push data',
      event_id,
      action,
      entity_id,
      path,
      url
    )

    if (url) {
      if (url.startsWith(wnhubEventPrefix)) {
        window.open(
          `/eventhub/index.html?redirect=${url.split(wnhubPrefix)[1]}`,
          '_self'
        )
        return
      }

      if (url.startsWith(wnhubPrefix)) {
        window.open(`${url.split(wnhubPrefix)[1]}`, '_self')
        return
      }

      Browser.open({ url })
      return
    } else if (action === PushActionType.CustomPush) {
      window.open('/users/me/notifications', '_self')
      return
    }

    if (path && path.startsWith('/events/')) {
      // support only event path for now
      window.open(`/eventhub/index.html?redirect=${path}`, '_self')
      return
    }

    if (event_id) {
      window.open(
        `/eventhub/index.html?event=${event_id}&action=${action}&entity=${entity_id}`,
        '_self'
      )
    } else {
      useLogError(
        new Error('no event_id data in push: ' + JSON.stringify(data))
      )
    }
  }

  const registerAppToken = async (token: string) => {
    try {
      await useAuthCookie().load()

      const authStore = useAuth()

      if (!authStore.auth) {
        return
      }

      await useAuthFetch('/user', {
        baseURL,
        method: 'POST',
        body: {
          platform,
          token,
          timezone: offset,
        },
      })

      console.log('native: register app token', platform, token, offset)
      appToken.value = token
    } catch (error: any) {
      useLogError(error)
    }
  }

  const removeAppToken = async () => {
    if (!isApp || !appToken.value) {
      return
    }

    try {
      await useAuthFetch('/user', {
        baseURL,
        method: 'DELETE',
        body: {
          token: appToken.value,
        },
      })
      appToken.value = null
    } catch (error: any) {
      useLogError(error)
    }
  }

  return {
    registerNotifications,
    addListeners,
    registerAppToken,
    removeAppToken,
  }
}
