<template>
  <div class="flex flex-col gap-1">
    <div class="flex gap-2 items-center">
      <div class="text-xl font-bold">
        {{
          isProActive
            ? activeText || $t('subscriptions.pro_account_active')
            : $t('subscriptions.pro_account')
        }}
      </div>
      <BaseIcon
        v-if="isProActive"
        name="outline_check_circle"
        class="text-green-500"
      />
      <BaseBadge
        v-else
        size="sm"
        theme="none"
        class="bg-primary text-white py-3"
      >
        {{ $t('subscriptions.pro') }}
      </BaseBadge>
    </div>
    <div v-if="isProActive && subscription" class="text-base">
      <template v-if="subscription.next_charge_at">
        {{
          `${$t('subscriptions.pro_head_cancel_text')} ${getHumanizedDate(
            subscription.next_charge_at
          )}`
        }}
      </template>
      <template v-else>
        {{
          `${$t('subscriptions.subscription_active_until')} ${getHumanizedDate(
            subscription.ends_at
          )}`
        }}
      </template>
    </div>
    <!-- <div class="font-bold text-lg">
      {{ $t('subscriptions.pro_head_description') }}
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
      <div class="flex flex-col gap-4">
        <SubscriptionsProHeaderFeature
          :title="$t('subscriptions.online_events')"
          icon="outline_globe_alt"
          color="bg-sky-400"
        />
        <SubscriptionsProHeaderFeature
          :title="$t('subscriptions.highlight_pro_account')"
          icon="outline_lightning_bolt"
          color="bg-pro"
        />
      </div>
      <div class="flex flex-col gap-4">
        <SubscriptionsProHeaderFeature
          :title="$t('subscriptions.priority_ranking')"
          icon="outline_bars_arrow_up"
          color="bg-emerald-400"
        />
        <SubscriptionsProHeaderFeature
          :title="$t('subscriptions.premium_content')"
          icon="outline_newspaper"
          color="bg-violet-500"
        />
      </div>
    </div> -->
  </div>
  <!-- <div class="sm:w-1/3 space-y-4">
      <SubscriptionsProHeaderFeature
        :title="$t('subscriptions.online_events')"
        icon="outline_globe_alt"
        color="bg-sky-400"
      />
      <SubscriptionsProHeaderFeature
        :title="$t('subscriptions.chats')"
        icon="outline_chat_alt_2"
        color="bg-violet-500"
      />
      <SubscriptionsProHeaderFeature
        :title="$t('subscriptions.premium_content')"
        icon="outline_newspaper"
        color="bg-amber-500"
      />
    </div> -->
</template>

<script setup lang="ts">
import type { Subscription } from '~/models/subscriptions'

interface PropsInterface {
  subscription?: Subscription | null
  isProActive: boolean
  activeText?: string
}

defineProps<PropsInterface>()

const { getHumanizedDate } = useDate()
</script>
