import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  shadowSchema,
  textColorSchema,
  buttonsPropertiesSchema,
  textFontFamilySchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Event Header',
  description: 'Event header block with cover, event info, and action buttons',
  type: 'object',
  properties: {
    ...basePropertiesSchema,
    ...bgColorSchema,
    ...textColorSchema,
    ...textFontFamilySchema,
    ...shadowSchema,
    hideImage: {
      title: 'Hide image',
      type: 'boolean',
      default: false,
    },
    hideBottomSection: {
      title: 'Hide section with event info and action buttons',
      type: 'boolean',
      default: false,
    },
    showOverlay: {
      title: 'Show dark overlay on image (for canceled events)',
      type: 'boolean',
      default: false,
    },
    overlayText: {
      title: 'Overlay text',
      type: 'string',
      'ui:options': {
        placeholder: 'This event has been rescheduled\nstay tuned for updates',
        type: 'textarea',
        rows: 2,
        maxlength: 75,
      },
      default: 'This event has been rescheduled\nstay tuned for updates',
      'ui:hidden': '{{ !parentFormData.showOverlay }}',
    },
    hideTitle: {
      title: 'Hide title',
      type: 'boolean',
      default: false,
    },
    hideDates: {
      title: 'Hide dates',
      type: 'boolean',
      default: false,
    },
    hideGoogleCalendar: {
      title: 'Hide Google Calendar',
      type: 'boolean',
      default: false,
    },
    hideHubDates: {
      title: 'Hide online platform (hub) availability dates',
      type: 'boolean',
      default: false,
    },
    hidePlace: {
      title: 'Hide place',
      type: 'boolean',
      default: false,
    },
    hideMeetingSystemButton: {
      title: 'Hide meeting system buttons',
      type: 'boolean',
      default: false,
    },
    hideSharing: {
      title: 'Hide sharing button',
      type: 'boolean',
      default: false,
    },
    hideFavoriteButton: {
      title: 'Hide favorite button',
      type: 'boolean',
      default: false,
    },
    ...buttonsPropertiesSchema,
  },
}
