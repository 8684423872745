import type { Media } from './media'
import type { GenericItem } from './common'
import type { TimezoneInterface } from './timezones'
import type { CountryInterface, CityInterface } from './geo'
import type { CompanyLite } from '~/models/companies'
import { execTransforms } from '~/utils/transform'
import type { Transforms } from '~/utils/transform'

export enum EmployeeRole {
  Chat = 'chat',
  Admin = 'admin',
}

export interface CompanyUser {
  id: number
  job: string | null
  first_name: string | null
  last_name: string | null
  company_roles?: EmployeeRole[]
  media: Media[]
}
// for comments/questions etc
export interface UserBasicData {
  id: number
  media: string
  name: string
  is_removed?: boolean
  is_subscription_active?: boolean
}

export interface UserProfile {
  id: number
  email?: string
  phone?: string | null
  first_name: string | null
  last_name: string | null
  company: CompanyLite | null
  company_id: number | null
  job: string | null
  bio: string | null
  media: Media[]
  timezone: TimezoneInterface | null
  country: CountryInterface | null
  city: CityInterface | null
  timezone_id: string | null
  country_id: string | null
  city_id: null | number
  looking_ids: number[]
  offer_ids: number[]
  telegram?: string | null
  whatsapp?: string | null
  wechat?: string | null
  portfolio: string
  linkedin: string
  offer: GenericItem[]
  looking: GenericItem[]
  disable_email_notifications?: boolean
  disable_promo_notifications?: boolean
  ticket: string | null // mocked
  roles?: string[]
  subscription_date_start?: string
  subscription_date_end?: string
  is_subscription_active: boolean
  [key: string]: any
}

// TODO: adapt decorators or some tool (zod, class-transformer)
const transforms: Transforms<UserProfile> = {
  company_id: (profile) => profile.company?.id,
  city_id: (profile) => profile.city?.id,
  country_id: (profile) => profile.country?.id,
  timezone_id: (profile) => (profile.timezone as TimezoneInterface)?.id,
  looking_ids: (profile) => (profile.looking || []).map((item) => item.value),
  offer_ids: (profile) => (profile.offer || []).map((item) => item.value),
}

export function transformProfile(profile: UserProfile) {
  return execTransforms<UserProfile>(transforms, profile)
}

export type UserProfileUpdate = Partial<{
  first_name: string
  last_name: string
  company_id: number | null
  job: string | null
  bio: string | null
  portfolio: string | null
  timezone_id: string | null
  country_id: string | null
  city_id: number | null
  disable_email_notifications: boolean
  phone: string | null
  linkedin: string | null
  telegram: string | null
  whatsapp: string | null
  wechat: string | null
  offer_ids: number[]
  looking_ids: number[]
}>
