import type { ProgramUser } from '~/models/program'
import type { UserProfile, CompanyUser } from '~/models/users'
import type { VideoSpeaker } from '~/models/video'

export function getAbbr(
  profile: UserProfile | VideoSpeaker | ProgramUser | null
) {
  if (!profile) {
    return ''
  }
  if (profile.first_name && profile.last_name) {
    return `${profile.first_name.charAt(0)}${profile.last_name.charAt(0)}`
  }

  if ('email' in profile && profile.email) {
    return `${profile.email.charAt(0)}${profile.email.charAt(1)}`
  }
  return ''
}

export function getAbbrFromFullName(fullName: string) {
  return fullName
    .split(' ')
    .map((part) => part[0])
    .join('')
}

export function getFullName(
  profile: UserProfile | VideoSpeaker | CompanyUser
): string {
  if (profile.first_name || profile.last_name) {
    return profile.first_name + ' ' + profile.last_name
  } else if ('email' in profile) {
    return profile.email || ''
  } else {
    return ''
  }
}

export function getAvatar(
  profile: UserProfile | VideoSpeaker | CompanyUser | null
) {
  // return 'http://dashboards.webkom.co/react/airframe/static/32.jpg'
  return profile?.media?.find((media) => media.category === 'logo')?.url || ''
}
