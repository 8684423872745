<template>
  <div
    :class="hasError && 'has-error'"
    style="scroll-margin-top: calc(4rem + var(--sat))"
  >
    <div>
      <label v-if="label" class="block mb-1 text-sm font-medium leading-5 px-4">
        {{ label }}
        {{ required ? '*' : '' }}
      </label>
      <div v-if="description" class="text-xs text-gray-500 mb-1 -mt-1 px-4">
        {{ description }}
      </div>
      <div
        v-if="options && options.length"
        class="flex gap-y-2 gap-x-6"
        :class="[isColumnView && 'flex-col items-start', listClass]"
      >
        <template v-for="option in options" :key="option.value">
          <label
            class="cursor-pointer flex items-baseline"
            :class="[
              disabled && 'opacity-60 pointer-events-none',
              listItemClass,
            ]"
          >
            <input
              v-model="value"
              type="radio"
              class="cursor-pointer flex-shrink-0"
              :value="option.value"
              :disabled="disabled"
              @input="onInput"
            />
            {{ option.label }}
          </label>
        </template>
      </div>
    </div>
    <div class="flex text-sm leading-4">
      <transition
        enter-active-class="transition-all duration-300"
        enter-from-class="transform -translate-y-3 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition-all duration-300"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-3 opacity-0"
      >
        <span v-if="hasError" class="text-red-600 mt-1 px-4">
          <slot name="error">
            {{ errorMessage }}
          </slot>
        </span>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RuleExpression } from 'vee-validate'
import { useSimpleInput } from './use-simple-input'
import type { OptionInterface } from './use-simple-input'

interface Props {
  label?: string
  description?: string
  modelValue?: string | number | null
  options: OptionInterface[]
  isColumnView?: boolean
  name?: string
  rules?: RuleExpression<string | number | object | boolean | any[] | null>
  error?: string
  disabled?: boolean
  required?: boolean
  listClass?: string
  listItemClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  description: '',
  modelValue: '',
  options: () => [],
  isColumnView: true,
  name: '',
  rules: '',
  error: '',
  disabled: false,
  required: false,
  listClass: '',
  listItemClass: '',
})

const emit = defineEmits(['update:modelValue', 'input'])

const { errorMessage, handleChange } = useSimpleInput(
  props,
  getCurrentInstance()
)

const slots = useSlots()
const hasError = computed(
  () => !props.disabled && (errorMessage.value || slots.error)
)

const value = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
    emit('input')

    handleChange(newValue, true)
  },
})

watch(
  () => props.modelValue,
  (value) => {
    handleChange(value, false)
  }
)
</script>

<style scoped>
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;

  border: 1px solid #bac2cc;
  transition: 0.2s all linear;
  outline: none;
  margin-right: 10px;

  position: relative;
  top: 2px;

  background-color: white;
}

input:checked {
  border: 4px solid #0d65d8;
}
</style>
