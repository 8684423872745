import { basePropertiesSchema } from '../base-schema-properties'
import { titlePropertiesSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Subscription',
  description: 'Subscription',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    subscriptionsTitle: {
      title: 'Subscriptions title',
      type: 'string',
      default: 'Confirm your billing plan',
    },
    activeText: {
      title: 'Subscription active text',
      type: 'string',
      default: 'PRO Account is active',
    },
    disclaimerText: {
      title: 'Disclaimer text',
      type: 'string',
      description:
        'You can use variables {{startDate}} and {{endDate}} in this text',
      default:
        'After subscribing, your credit/debit card will be billed each month with the same amount. Your subscription begins on **{{startDate}} and will end on {{endDate}}**. You can cancel anytime before {{endDate}} to avoid being charged. A month is always considered to be 30 days.\n\nPlease note that if you cancel within the **first three months**, you will not be able to reactivate your membership for the next **six months**. This ensures that WN Pro remains an exclusive and committed community.',
      'ui:widget': 'EditorMarkdownWidget',
      'ui:options': {
        placeholder: 'Disclaimer text',
        type: 'textarea',
        rows: 6,
      },
    },
  },
}
