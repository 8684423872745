export const useEditorFontFamily = (fontFamilyValue: string | undefined) => {
  const systemFontFamily = 'ui-sans-serif, system-ui, sans-serif'

  if (!fontFamilyValue || usePageEditor().isInIframe) {
    return undefined
  }

  // system font
  if (fontFamilyValue === 'system') {
    return {
      fontFamily: systemFontFamily,
    }
  }

  // google font
  return {
    fontFamily: fontFamilyValue + ', ' + systemFontFamily,
    fontOpticalSizing: 'auto',
  } as { fontFamily: string; fontOpticalSizing: 'auto' }
}
