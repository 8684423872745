<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      color: formData.textColor,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
      '--text-color': formData.textColor,
      ...useEditorFontFamily(formData.textFontFamily),
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-6"
      :style="{
        textAlign: formData.titleAlign,
        fontWeight: formData.titleWeight,
        fontStyle: formData.titleStyle,
        color: formData.titleColor,
        fontSize: formData.titleSizePx,
        ...useEditorFontFamily(formData.titleFontFamily),
      }"
    >
      {{ formData.title }}
    </h2>
    <div class="flex flex-col gap-4 md:hidden">
      <div
        v-for="(tab, index) in formData.tabs"
        :key="index"
        class="flex flex-col gap-2 items-center"
      >
        <div class="font-bold text-lg text-center">
          {{ tab.label }}
        </div>
        <div
          v-if="tab.image"
          :class="[
            tab.imageWidth,
            tab.isImageRounded && 'rounded-xl overflow-hidden',
          ]"
          class="mt-3"
        >
          <img :src="tab.image" class="w-full" />
        </div>
        <div
          class="tab-text marked"
          v-html="getMarkedTextWithBlankLinks(tab.text)"
        />
      </div>
    </div>
    <div class="hidden md:grid grid-cols-2 xl:grid-cols-3 gap-8">
      <div class="flex flex-col gap-2 items-start">
        <template v-for="(tab, index) in formData.tabs" :key="index">
          <BaseButton
            look="none"
            size="md"
            class="text-md !h-auto min-h-10 max-w-full"
            :class="index === activeTab ? 'active-tab-button' : 'tab-button'"
            @click="activeTab = index"
          >
            <div class="whitespace-normal text-left py-3">
              {{ tab.label }}
            </div>
          </BaseButton>
        </template>
      </div>
      <div class="xl:col-span-2 flex flex-col gap-2">
        <div
          v-if="formData.tabs[activeTab].image"
          :class="[
            formData.tabs[activeTab].imageWidth || 'w-1/3',
            formData.tabs[activeTab].isImageRounded &&
              'rounded-xl overflow-hidden',
          ]"
        >
          <img :src="formData.tabs[activeTab].image" class="w-full" />
        </div>
        <div
          class="tab-text marked"
          :class="!formData.tabs[activeTab].image && 'pt-1'"
          v-html="getMarkedTextWithBlankLinks(formData.tabs[activeTab].text)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()

const activeTab = ref(0)

const activeTabButtonColor = computed(() => props.formData.activeTabButtonColor)
const activeTabTextColor = computed(() => props.formData.activeTabTextColor)
</script>

<style lang="scss" scoped>
.tab-button:hover,
.active-tab-button {
  background-color: v-bind(activeTabButtonColor);
  color: v-bind(activeTabTextColor);
}
</style>

<style lang="scss">
.tab-text.marked {
  h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
