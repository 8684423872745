import { DEFAULT_MARKDOWN_TEXT, DEFAULT_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  titlePropertiesSchema,
  shadowSchema,
  bgColorSchema,
  linkColorSchema,
  textColorSchema,
  textFontFamilySchema,
  showAllSettingsSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Accordion',
  description: 'List with drop-down texts',
  type: 'object',
  required: ['tabs', 'textColor'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  // be careful - dependencies makes all dependent fields required
  // if it's ok, use it, if not - use 'ui:hidden' instead
  dependencies: {
    tmp_showAllSettings: [
      'textColor',
      'bgColor',
      'linkColor',
      'blockShadow',
      'numeration',
      'align',
      'maxWidth',
      'imageTopPadding',
      'closeOther',
      'itemsTitlesColor',
      'itemsBgColor',
      'titleColor',
      'imagePosition',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    titleColor: {
      ...textColorSchema.textColor,
      title: 'Title color',
    },
    ...bgColorSchema,
    itemsBgColor: {
      title: 'Items background color',
      type: 'string',
      default: '#F4F5F6',
      'ui:widget': 'BaseColorWidget',
    },
    itemsTitlesColor: {
      ...textColorSchema.textColor,
      title: 'Items title color',
    },
    ...textColorSchema,
    textFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    ...linkColorSchema,
    ...shadowSchema,
    numeration: {
      title: 'Add numeration for list items',
      type: 'boolean',
      default: false,
    },
    closeOther: {
      title: 'Close other items on click',
      type: 'boolean',
    },
    imagePosition: {
      title: 'Image position',
      type: 'string',
      enum: ['left', 'right'],
      enumNames: ['Left', 'Right'],
      default: 'left',
      'ui:hidden': '{{!parentFormData.image}}',
    },
    align: {
      title: 'Content align',
      type: 'string',
      enum: ['justify-start', 'justify-center', 'justify-end', 'w-full'],
      enumNames: ['Left', 'Center', 'Right', 'Full width'],
      default: 'w-full',
      'ui:hidden': '{{!!parentFormData.image}}',
    },
    maxWidth: {
      title: 'Max width of the text (in px)',
      description:
        "If it's not set, the text will be 100% width of the block (default is 576)",
      type: 'number',
      'ui:hidden':
        '{{!!parentFormData.image || parentFormData.align === "w-full"}}',
    },
    image: {
      title: 'Image',
      type: 'string',
      default: '',
    },
    tabs: {
      type: 'array',
      title: 'Tabs',
      minItems: 2,
      items: {
        type: 'object',
        required: ['label', 'text'],
        minItems: 1,
        'ui:options': {
          showIndexNumber: true,
        },
        properties: {
          label: {
            title: 'Tab label',
            type: 'string',
            default: DEFAULT_TEXT,
          },
          text: {
            title: 'Text',
            type: 'string',
            default: DEFAULT_MARKDOWN_TEXT,
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_TEXT,
              type: 'textarea',
              rows: 6,
            },
          },
        },
      },
    },
  },
}
