import type { EventType } from './events'
import type { Media } from './media'

export enum ProgramSectionTypes {
  Registration = 1,
  Lecture,
  CoffeeBreak,
  RoundTable,
  Deconstruction,
  Party,
  AwardsCeremony,
  None,
  SoloTalk,
  DuoTalk,
  PanelDiscussion,
  FiresideChat,
  HappyHour,
  KeynoteSpeaker,
  QAndA,
  Lunch,
  TrioTalk,
  Masterclass,
  InteractiveSession,
  IndiePitch,
}

export enum ProgramParticipantEntityType {
  ProgramUser = 5,
  ProgramCompany = 6,
}

export const SpecialSections = [
  ProgramSectionTypes.CoffeeBreak,
  ProgramSectionTypes.HappyHour,
]

export enum ProgramParticipantTypes {
  Sponsor = 1,
  Speaker,
  Moderator,
  Jury,
  Defendant,
  Guest,
  Interviewer,
  WnAcademyExpert,
}

export interface ProgramTemplateParams {
  accentColor: string | null
  backgroundColor: string | null
  badgeColors: { color: string; sectionTypeId: ProgramSectionTypes }[]
  hub: {
    accentColor: string | null
    backgroundColor: string | null
    textColor: string | null
  }
  print: {
    accentColor: string | null
    backgroundColor: string | null
    streamsColors: []
    textColor: string | null
  }
  textColor: string | null
}

export interface ProgramTemplate {
  id: number
  title: string
  params: ProgramTemplateParams
  description: string | null
}

export type HrefType = 'outer' | 'company' | 'user' | 'game'

export interface ProgramUser {
  media: Media[]
  first_name: string
  last_name: string
  job_title: string | null
  company_name: string | null
  href_type: HrefType | null
  href_link: string | null
  href_id: number | null
}

export interface ProgramCompany {
  media: Media[]
  name: string
  href_type: HrefType
  href_link: string
  href_id: number
}

export interface ProgramSponsor {
  company_id: number
  id: number
  program_company: ProgramCompany
  stream_id: number
  section_type_id: number
  sort: number
}

export interface ProgramParticipant {
  type: ProgramParticipantTypes
  entity_type: number
  entity: ProgramUser | ProgramCompany
}

export function instanceOfProgramUser(obj: any): obj is ProgramUser {
  return 'first_name' in obj
}

export function instanceOfProgramCompany(obj: any): obj is ProgramCompany {
  return 'name' in obj
}

export interface ProgramSection {
  id: number
  title: string
  type: ProgramSectionTypes
  description: string | null
  start: string
  end: string
  participants: ProgramParticipant[]
  is_buttons_hidden?: boolean
  is_time_hidden?: boolean
}

export interface ProgramStream {
  id: number
  title: string
  sort: number
  sections: ProgramSection[]
  sponsors: ProgramSponsor[]
}

export interface ProgramDay {
  id: number
  title: string
  date: string
  streams: ProgramStream[]
}

export interface Program {
  id: number
  event_id: number
  template: ProgramTemplate
  title: string
  description?: string | null
  timezone: string
  timezone_label?: string
  days: ProgramDay[]
  current_day_id: number | null
}

export interface ProgramSectionExtended extends ProgramSection {
  day_date: string
  event_address: string | null
  event_address_link: string | null
  event_id: number
  event_is_onsite: boolean
  event_is_online: boolean
  event_type: EventType
  event_name: string
  event_slug: string
  event_city: string
}
