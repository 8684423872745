<template>
  <div
    :id="uuid"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor || '#ffffff',
      color: formData.textColor || '#414141',
      '--text-color': formData.textColor,
      ...useEditorFontFamily(formData.textFontFamily),
      border:
        formData.border && formData.borderColor
          ? `2px solid ${formData.borderColor}`
          : undefined,
    }"
    :class="[formData.blockShadow && 'shadow-lg']"
    class="flex flex-col items-center gap-4 p-6 md:p-8 rounded-xl"
  >
    <div
      class="flex flex-col items-center gap-6 relative w-full"
      :style="{
        maxWidth: formData.maxWidth ? `${formData.maxWidth}px` : '576px',
      }"
    >
      <div
        v-if="
          formStatus === FormStatus.Success || formStatus === FormStatus.Error
        "
        class="text-center w-full h-full flex items-center justify-center"
      >
        <div v-if="formStatus === FormStatus.Success">
          <span
            v-if="formData.successMessage"
            class="marked"
            v-html="
              getMarkedTextWithBlankLinks(
                formData.successMessage,
                formData.linkColor
              )
            "
          />
          <span v-else>
            {{ t('notifications.editor.send_form_success_default_text') }}
          </span>
        </div>
        <!-- notifications don't work in iframe -->
        <div v-if="formStatus === FormStatus.Error">
          {{ t('notifications.editor.send_form_error_default_text') }}
        </div>
      </div>
      <template v-else>
        <div
          v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
          :style="{
            textAlign: formData.titleAlign || 'center',
            fontWeight: formData.titleWeight,
            fontStyle: formData.titleStyle,
            color: formData.titleColor,
            fontSize: formData.titleSizePx,
            ...useEditorFontFamily(formData.titleFontFamily),
          }"
          class="w-full text-3xl font-bold"
        >
          {{ formData.title }}
        </div>
        <div
          v-if="formData.description"
          class="marked"
          v-html="
            getMarkedTextWithBlankLinks(
              formData.description,
              formData.linkColor
            )
          "
        />
        <BaseForm
          class="w-full flex flex-col margin-x-auto gap-4"
          :method="submit"
          :reset-on-submit="false"
        >
          <template
            v-for="(field, index) in formData.fields"
            :key="field.title + index"
          >
            <BaseInput
              v-if="field.type === FormFieldType.Name"
              v-model="form[field.title].value"
              :name="field.title"
              :type="FormFieldType.Text"
              :placeholder="field.placeholder"
              :label="field.title"
              :required="field.required"
              input-background-class="bg-white"
              input-text-class="text-gray-900"
            />
            <BaseInput
              v-if="
                field.type === FormFieldType.Text ||
                field.type === FormFieldType.Date ||
                field.type === FormFieldType.Datetime ||
                field.type === FormFieldType.Email ||
                field.type === FormFieldType.Job ||
                field.type === FormFieldType.Company ||
                field.type === FormFieldType.Time
              "
              v-model="form[field.title].value"
              :name="field.title"
              :type="field.type"
              :placeholder="field.placeholder"
              :label="field.title"
              :rules="{
                email: field.type === FormFieldType.Email,
                required: field.required,
              }"
              :required="field.required"
              input-background-class="bg-white"
              input-text-class="text-gray-900"
            />
            <BaseInput
              v-else-if="field.type === FormFieldType.Number"
              v-model="form[field.title].value"
              :name="field.title"
              :type="field.type"
              :placeholder="field.placeholder"
              :label="field.title"
              :required="field.required"
              :min="field.min"
              :max="field.max"
              input-background-class="bg-white"
              input-text-class="text-gray-900"
            />
            <BaseCheckbox
              v-else-if="field.type === FormFieldType.Checkbox"
              v-model="form[field.title].value"
              :name="field.title"
              :label="field.title"
              :required="field.required"
              background-class="bg-white"
            />
            <BaseTextarea
              v-else-if="field.type === FormFieldType.Textarea"
              v-model="form[field.title].value"
              :name="field.title"
              :label="field.title"
              :placeholder="field.placeholder"
              :rows="field.rowsNumber"
              :required="field.required"
              input-background-class="bg-white"
              input-text-class="text-gray-900"
            />
            <BaseRadioGroup
              v-else-if="field.type === FormFieldType.Radio"
              v-model="form[field.title].value"
              :name="field.title"
              :label="field.title"
              :options="field.options"
              :is-column-view="false"
              :required="field.required"
            />
            <BaseSelect
              v-else-if="field.type === FormFieldType.Select"
              v-model="form[field.title].value"
              :name="field.title"
              :label="field.title"
              :options="field.options"
              :placeholder="field.placeholder || t('common.select')"
              :required="field.required"
              text-class="text-gray-900"
            />
            <BaseSelect
              v-else-if="field.type === FormFieldType.MultiSelect"
              v-model="form[field.title].value"
              :name="field.title"
              :label="field.title"
              :options="field.options"
              :placeholder="field.placeholder || t('common.select')"
              :required="field.required"
              :show-clear-button="false"
              text-class="text-gray-900"
              multiple
            />
            <FormFileInput
              v-else-if="field.type === FormFieldType.File"
              v-model="form[field.title].value"
              :accept="[
                'image/jpg',
                'image/jpeg',
                'image/gif',
                'application/pdf',
              ]"
              :max-size="10"
              rounded="xl"
              :name="field.title"
              :label="field.title"
              :placeholder="field.placeholder || t('common.select')"
              :required="field.required"
              :rules="{ required: field.required }"
              input-block-class="bg-white rounded-xl"
            />
          </template>
          <div class="flex justify-center pt-4">
            <BaseButton
              theme="primary"
              :look="formData.buttonStyle"
              type="submit"
              class="hover:brightness-110 duration-150"
              :style="buttonStyle"
              :loading="isLoading"
            >
              {{ formData.buttonText }}
            </BaseButton>
          </div>
        </BaseForm>
        <div
          v-if="formData.textUnderForm"
          class="marked pt-4"
          v-html="
            getMarkedTextWithBlankLinks(
              formData.textUnderForm,
              formData.linkColor
            )
          "
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { FormFieldType } from './schema'
import FormFileInput from './components/FormFileInput.vue'
import type { OptionInterface } from '~/components/base/controls/use-simple-input'
import BaseForm from '~/components/base/BaseForm.vue'
import { usePageEditorForms } from '~/stores/page-editor-forms'
import { useAuth } from '~/stores/auth'
import { useNotifications } from '~/stores/notifications'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface FieldInterface {
  title: string
  type: FormFieldType
  placeholder?: string
  default?: boolean | string
  required?: boolean
  options?: OptionInterface[]
  min?: number
  max?: number
  rowsNumber?: number
}

interface FormDataInterface {
  title: string
  titleAlign?: 'left' | 'center' | 'right'
  titleWeight?: string
  titleStyle?: string
  titleSizePx?: string
  titleColor?: string
  hideOnEmbed?: boolean
  description?: string
  textColor?: string
  buttonText: string
  buttonColor?: string
  bgColor?: string
  linkColor?: string
  border?: boolean
  borderColor?: string
  buttonStyle: 'solid' | 'border'
  successMessage?: string
  textUnderForm?: string
  fields: FieldInterface[]
  eventId?: number
  maxWidth?: number
  emailTemplate?: number
  blockShadow?: boolean
  action: 'default' | 'marketplace-product-request'
  textFontFamily?: string
  titleFontFamily?: string
}

interface PropsInterface {
  uuid: string
  blockUuid: string
  blockId: number
  formData: FormDataInterface
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
  pageLink: '',
})

const { getMarkedTextWithBlankLinks } = useMarkedText()
const { sendRequest, sendProductRequest } = usePageEditorForms()
const authStore = useAuth()
const { pushNotification } = useNotifications()
const { t } = useI18n()
const route = useRoute()
const host = useHost()

enum FormStatus {
  None,
  Success,
  Error,
}

const formStatus = ref(FormStatus.None)
const isLoading = ref(false)

interface FormFieldInterface {
  type: FormFieldType
  value: any
  label: string
  options: OptionInterface[]
}

const form = reactive<Record<string, FormFieldInterface>>({})

const setFormFields = () => {
  props.formData.fields.forEach((field) => {
    let value

    if (authStore.profile) {
      if (field.type === FormFieldType.Name) {
        value = getFullName(authStore.profile)
      } else if (field.type === FormFieldType.Email) {
        value = authStore.profile.email
      } else if (field.type === FormFieldType.Job && authStore.profile.job) {
        value = authStore.profile.job
      } else if (
        field.type === FormFieldType.Company &&
        authStore.profile.company
      ) {
        value = authStore.profile.company.name
      }
    }

    if (field.type === FormFieldType.Checkbox) {
      value = !!field.default || false
    }

    form[field.title] = {
      type: field.type,
      value,
      label: field.title,
      options: field.options || [], // for selectors and radio, because we need to send meaningful values to the backend
    }
  })
}

const formatRequestCommonData = () => {
  const fields = Object.keys(form).map((key) => {
    const field = form[key]

    let valueFormatted = field.value

    if (
      field.type === FormFieldType.Radio ||
      field.type === FormFieldType.Select
    ) {
      const selectedOption = field.options.find(
        (item) => item.value === field.value
      )

      valueFormatted = selectedOption
        ? JSON.stringify({
            label: selectedOption?.label,
            value: selectedOption.value,
          })
        : ''
    }

    if (
      field.type === FormFieldType.MultiSelect &&
      Array.isArray(field.value)
    ) {
      const selectedOptions = field.value.reduce((acc, valueItem) => {
        const option = field.options.find((item) => item.value === valueItem)

        if (option) {
          acc.push({
            label: option?.label,
            value: option.value,
          })
        }

        return acc
      }, [] as OptionInterface[])

      valueFormatted = JSON.stringify(selectedOptions)
    }

    if (typeof valueFormatted !== 'string') {
      valueFormatted = JSON.stringify(valueFormatted)
    }

    return {
      type: field.type,
      value: valueFormatted,
      label: field.label,
    }
  })

  const email =
    Object.values(form).find((item) => item.type === FormFieldType.Email)
      ?.value || ''

  const name =
    Object.values(form).find((item) => item.type === FormFieldType.Name)
      ?.value || ''

  // we send name and email in the special request fields
  const filteredFields = fields.filter(
    (item) =>
      item.type !== FormFieldType.Name && item.type !== FormFieldType.Email
  )

  return {
    ...(email && { email }),
    ...(name && { name }),
    ...(filteredFields.length && {
      data: {
        fields: filteredFields,
      },
    }),
    page_url: `${host}${route.path}`,
  }
}

function formatRequestDefaultData() {
  return {
    ...(props.formData.eventId && { event_id: props.formData.eventId }),
    ...(props.formData.emailTemplate && {
      email_template: props.formData.emailTemplate,
    }),
    page_title: document.title,
    block_id: props.blockId,
    block_uuid: props.blockUuid,
    form_name: props.formData.title,
    ...formatRequestCommonData(),
  }
}

function formatRequestProductData() {
  const productId = Array.isArray(route.params.productId)
    ? Number(route.params.productId[0])
    : Number(route.params.productId)
  return {
    ...(authStore.profile?.id && { user_id: authStore.profile?.id }),
    product_id: productId,
    ...formatRequestCommonData(),
  }
}

const submit = async () => {
  isLoading.value = true
  try {
    if (props.formData.action === 'marketplace-product-request') {
      await sendProductRequest(formatRequestProductData())
    } else {
      await sendRequest(formatRequestDefaultData())
    }
    pushNotification({
      title: t('notifications.editor.send_form_success'),
    })
    formStatus.value = FormStatus.Success
  } catch (error) {
    pushNotification({
      title: t('notifications.editor.send_form_error'),
      theme: 'destructive',
    })
    useLogError(error)
    formStatus.value = FormStatus.Error
  } finally {
    isLoading.value = false
  }
}

const buttonStyle = computed(() => {
  if (props.formData.buttonStyle === 'border') {
    return {
      border: `2px solid ${props.formData.buttonColor}`,
      color: props.formData.buttonColor,
    }
  } else {
    return {
      'background-color': props.formData.buttonColor,
      color: 'white',
    }
  }
})

watch(
  () => props.formData.fields,
  () => {
    setFormFields()
  },
  { deep: true, immediate: true }
)
</script>
